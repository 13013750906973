import { JoinCompanyProjectList_ProjectFragment } from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { UnArchiveEnterpriseButton } from './UnArchiveEnterpriseButton';
import React from 'react';
import { getDateFromISOString } from '../../../../services/DateService';

export function CompanyItem({
  company,
}: {
  company: JoinCompanyProjectList_ProjectFragment;
}) {
  const { t, i18n } = useTranslation();
  return (
    <tr key={company.id}>
      <td>
        <div>
          <div className="font-bold">{company.name}</div>
          <div className="text-xs text-gray-500 italic font-light">
            {company.createdBy?.firstName
              ? t('home.projects.table.createdAt_by', {
                  createdAt: getDateFromISOString(
                    company.createdAt,
                    i18n.language,
                  ),
                  author:
                    company.createdBy?.firstName +
                    ' ' +
                    company.createdBy?.lastName,
                })
              : t('home.projects.table.createdAt', {
                  createdAt: getDateFromISOString(
                    company.createdAt,
                    i18n.language,
                  ),
                })}
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-end">
          <UnArchiveEnterpriseButton company={company} />
        </div>
      </td>
    </tr>
  );
}
