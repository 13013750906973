import {
  IroEvaluation_IroDocument,
  IroEvaluationHeader_StakeFragment,
  IroType,
  SurveyLanguage,
  useCreateIroModalMutation,
} from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { IroIcon } from '../../IroIcon';
import { iroTypeToTypename } from './../row/IroRow';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { CheckIcon, PlusIcon } from '../../../../icons';
import { Suggestion } from './IroSuggestionsDrawer';

export function IroSuggestionTable({
  type,
  stake,
  iros,
  referentialId,
}: {
  type: IroType;
  stake: IroEvaluationHeader_StakeFragment;
  iros: Suggestion[];
  referentialId: string;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState(iros);
  const [createIro] = useCreateIroModalMutation();

  const saveIro = (description: string) => {
    createIro({
      variables: {
        input: {
          //valueChainItem: valueChainItem?.id as ValueChainItemEnum,
          type,
          stake: {
            id: stake.id,
          },
          i18n: [{ language: SurveyLanguage.Fr, name: description }],
        },
      },
      refetchQueries: () => [
        {
          query: IroEvaluation_IroDocument,
          variables: {
            stakeId: stake.id,
          },
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:iro.form.create.success'));
      })
      .catch(() => {
        toast.openToastWithError(t('toast:iro.form.create.error'));
      })
      .finally(() => {
        setCellOnEdit(undefined);
        const suggestion = suggestions.find(
          (iro) => iro.description === description,
        );
        if (suggestion) suggestion.added = true;
      });
  };

  function calculateRowsNeeded(text: string, textareaWidthInChars: number) {
    // Split the text into words
    const words = text.split(/\s+/);

    let currentLineLength = 0;
    let numberOfLines = 1; // Start with one line

    for (const word of words) {
      if (currentLineLength + word.length > textareaWidthInChars) {
        // Word doesn't fit in the current line, move to the next line
        numberOfLines++;
        currentLineLength = 0;
      }
      // Add the word to the current line
      currentLineLength += word.length + 1; // +1 for the space after the word
    }

    return numberOfLines;
  }

  const [cellOnEdit, setCellOnEdit] = useState<Suggestion | undefined>(
    undefined,
  );

  function editCell(iro: Suggestion) {
    setCellOnEdit(iro);
  }

  function updateIroOnEdit(text: string) {
    const newValue = { ...cellOnEdit, description: text };
    setCellOnEdit({ ...cellOnEdit, description: text });

    function synchronizeSuggestionsWithCellState() {
      const suggestion = suggestions.find(
        (i) => i.randomId === cellOnEdit?.randomId,
      );
      if (suggestion) {
        suggestion.description = newValue.description;
      }
    }

    synchronizeSuggestionsWithCellState();
  }

  return (
    <table className="table-auto">
      <thead>
        <tr>
          <th className={clsx('sticky left-0 font-bold text-base')}>
            <div className="flex items-center gap-2 capitalize justify-between">
              <div className="flex items-center gap-2 text-gray-900">
                <IroIcon iroTypename={iroTypeToTypename(type)} />
                {t(`translation:iro.types.${type}`)}
              </div>
            </div>
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        {suggestions && suggestions.length === 0 && (
          <tr>
            <td colSpan={2} className="text-center">
              {t('translation:iro.suggestions.empty')}
            </td>
          </tr>
        )}
        {suggestions &&
          suggestions.map((iro) => (
            <tr key={iro.randomId}>
              <td
                onClick={() => !iro.added && editCell(iro)}
                className={clsx('bg-white border-gray-100')}
              >
                {cellOnEdit?.randomId === iro.randomId ? (
                  <textarea
                    rows={calculateRowsNeeded(iro.description, 30)}
                    className="form-input-text"
                    value={cellOnEdit?.description ?? ''}
                    onChange={(e) => updateIroOnEdit(e.target.value)}
                  />
                ) : (
                  <div
                    className={clsx(
                      'border rounded-lg shadow p-2',
                      !iro.added || 'bg-gray-50 text-gray-900',
                    )}
                  >
                    {iro.description}
                  </div>
                )}
              </td>
              <td className={clsx('bg-white text-center p-0 pr-5')}>
                <div className="flex items-center justify-center ">
                  {!iro.added && (
                    <button
                      onClick={() => saveIro(iro.description)}
                      className={'text-sm'}
                    >
                      <PlusIcon /> {t('global:add')}
                    </button>
                  )}
                  {iro.added && (
                    <div className="flex items-center gap-2 border-2 px-4 py-2 border-green-500 font-semibold text-sm rounded-lg text-green-500">
                      <CheckIcon /> {t('global:added')}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
