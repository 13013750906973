import {
  IroEvaluationHeader_StakeFragment,
  IroType,
} from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import React, { useState } from 'react';
import {
  ChevronDownIcon,
  PlusIcon,
  TwinklingStarsIcon,
} from '../../../../icons';
import clsx from 'clsx';
import { useRightDrawer } from '../../../../layout/RightDrawer';
import { IroSuggestionsDrawer } from '../suggestionsDrawer/IroSuggestionsDrawer';
import { CreateIroModal } from '../form/create/CreateIroModal';
import { useModal } from 'components/layout/Modal';

export const CreateIroMenu = ({
  stake,
  iroType,
  referentialId,
  iros,
  topics,
}: {
  stake: IroEvaluationHeader_StakeFragment;
  iroType: IroType;
  referentialId: string;
  iros: any[];
  topics: string[];
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [showMenu, setShowMenu] = useState(false);
  const rightDrawer = useRightDrawer();
  const openIroSuggestionsModal = () => {
    rightDrawer.openDrawerWithComponent(
      <>
        <IroSuggestionsDrawer
          {...{ iroType: iroType, stake, referentialId, iros, topics }}
        />
      </>,
    );
  };
  const openCreateIroModal = () => {
    modal.openModalWithComponent(
      <CreateIroModal
        {...{ type: iroType, stakeId: stake.id, referentialId }}
      />,
      null,
      false,
      false,
      undefined,
      'p-0',
    );
  };

  return (
    <div
      className="relative font-normal"
      onMouseLeave={() => setShowMenu(false)}
    >
      <div>
        <div className="flex items-center justify-between gap-4 ">
          <button
            onClick={() => setShowMenu(!showMenu)}
            className={
              'h-8 border-purple-500 text-purple-500 hover:bg-purple-300  hover:text-purple-500 hover:border-purple-500'
            }
          >
            {t('global:add')}
            <ChevronDownIcon className={clsx(showMenu && 'rotate-180')} />
          </button>
        </div>
      </div>
      {showMenu && (
        <div
          onMouseEnter={() => setShowMenu(true)}
          className="absolute top-full left-0 -mt-2 pt-4 z-50"
        >
          <div className="bg-white p-1 border rounded-lg shadow-sm">
            <div
              className={
                'p-2 rounded border-purple-500 text-purple-500 hover:text-purple-500 hover:bg-purple-300 font-semibold'
              }
            >
              <a
                className="flex items-center gap-2 cursor-pointer text-lg"
                onClick={openCreateIroModal}
              >
                <PlusIcon />
                <span className="text-nowrap">{t('global:create_new')}</span>
              </a>
            </div>
            <div
              className={
                'p-2 rounded border-purple-500 text-purple-500 hover:text-purple-500 hover:bg-purple-300 font-semibold'
              }
            >
              <a
                onClick={openIroSuggestionsModal}
                className={'cursor-pointer text-lg flex items-center gap-2 '}
              >
                <span className="text-xl ">
                  <TwinklingStarsIcon className="text-xl" />
                </span>
                <span>{t('global:generate')}</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
