import { useTranslation } from '@hooks/useTranslation';
import { NoData } from '../../../generic/NoData';
import { ProgressDoughnutChart } from '../../../generic/chart/ProgressDoughnutChart';
import React from 'react';

export function AvailableDatapointsVsSelectedDatapointsDoughnut({
  availableDatapointsCount,
  selectedDatapointsCount,
}: {
  availableDatapointsCount: number;
  selectedDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (availableDatapointsCount === 0 && selectedDatapointsCount === 0) {
    return <NoData text={t('global:noResult')} actions={[]}></NoData>;
  }

  return (
    <ProgressDoughnutChart
      progress={availableDatapointsCount}
      total={selectedDatapointsCount}
      progressLabel={t(
        'gap_analysis.synthesis.availableDatapointsOverSelectedChart.availableDatapoints',
      )}
      totalLabel={t(
        'gap_analysis.synthesis.availableDatapointsOverSelectedChart.selectedDatapoints',
      )}
      title={t(
        'gap_analysis.synthesis.availableDatapointsOverSelectedChart.title',
      )}
    />
  );
}
