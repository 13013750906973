import { IroContextualMenu } from '../IroContextualMenu';
import React from 'react';
import {
  IroCellName_IroFragment,
  SurveyLanguage,
  ValueChainItemEnum,
} from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { MissingTranslationsWarningIcon } from '../../../../../generic/MissingTranslationsWarningIcon';
import { IroFragment } from '../IroRow';
import { hasHasPotentialNegativeHumanRightsImpactCriteria } from '../../../../../../types/iro.types';
import { JustifyIroButton } from '../../../../audit/justification/type/JustifyIroButton';
import { BuildingIcon } from '../../../../../icons';
import { Tag } from '../../../../../badges/Tag';

export function IroNameCell({
  iro,
}: {
  iro: IroCellName_IroFragment & IroFragment;
}) {
  const { translateProperty, i18n, t } = useTranslation();

  return (
    <div className="flex items-center gap-1 justify-between">
      <div className="grow space-y-1">
        <div className="text-base">
          {translateProperty(iro, 'name')}
          <MissingTranslationsWarningIcon
            entity={iro}
            properties={['name']}
            languages={[i18n.language as SurveyLanguage]}
          />
        </div>
        <IroTags iro={iro} />
      </div>
      <div className="flex items-center gap-2">
        <div className="invisible group-hover:visible">
          <IroContextualMenu
            {...{
              iro,
            }}
          />
        </div>
        <JustifyIroButton iroId={iro.id} />
      </div>
    </div>
  );
}

function IroTags({ iro }: { iro: IroCellName_IroFragment & IroFragment }) {
  const { t } = useTranslation();

  const potentialNegativeHumanRightsImpactCriteria =
    hasHasPotentialNegativeHumanRightsImpactCriteria(iro) &&
    iro.hasPotentialNegativeHumanRightsImpact === true;

  const hasOwnOperationsValueChainItem = iro.valueChainItems?.find(
    (valueChainItem) => valueChainItem === ValueChainItemEnum.OwnOperations,
  );

  return (
    <div className="flex items-center gap-1 flex-wrap">
      {potentialNegativeHumanRightsImpactCriteria && (
        <Tag color={'red'} small={true}>
          {t('iro.criteria.hasPotentialNegativeHumanRightsImpact')}
        </Tag>
      )}

      {(iro.valueChainItems || []).map((valueChainItem) => (
        <div key={valueChainItem} className="tag blue small">
          {t(`enum:valueChainItem.${valueChainItem}`)}
        </div>
      ))}

      {hasOwnOperationsValueChainItem &&
        (iro.organizationalUnits || []).length === 0 && (
          <Tag color="blue" small={true}>
            <BuildingIcon className="w-4 h-4" />
            {t(`enum:valueChainItem.global`)}
          </Tag>
        )}

      {hasOwnOperationsValueChainItem &&
        (iro.organizationalUnits || []).map((ou) => (
          <Tag key={ou.id} color="blue" small={true}>
            <BuildingIcon className="w-4 h-4" />
            {ou.name}
          </Tag>
        ))}
    </div>
  );
}
