import {
  MaterialityTypes,
  StakesMaterialityChart_StakeFragment,
  StakesQualityChart_AnswerFragment,
} from '../../../../../../graphql/generated';
import React, { useState } from 'react';
import StringDropdown from '../../../../../generic/dropdown/StringDropdown';
import { StringDropdownItem } from '../../../../../generic/dropdown/StringDropdown.types';
import { TStakeMateriality } from '../../../../survey/questionsDataviz/stakesMaterialityChart/StakesMaterialityChart';
import PillarDropdown from '../../../../../form/PillarDropdown';
import { StakeTag } from '../../../../../stake/StakeTag';
import { QualityBarChart } from '../../../../survey/questionsDataviz/QualityBarChart';
import { ExportMatrixContentToXlsButton } from './ExportMatrixContentToXlsButton';
import { mapQualityLevelToNumber } from './utils';
import { StakeQualityAverageType } from './types';
import { useProjectContext } from '../../../../../../providers/ProjectContextProvider';

// Build a map stake -> quality sum and count from answers
type StakeQualityMapType = {
  [stakeId: string]: { sum: number; count: number };
};

export const StakesQualityChart = ({
  stakes,
  answers,
  materialityType,
}: {
  stakes: StakesMaterialityChart_StakeFragment[];
  answers: StakesQualityChart_AnswerFragment[];
  materialityType: MaterialityTypes;
}) => {
  const currentProject = useProjectContext();

  enum SortBy {
    QualityAsc = 'QualityAsc',
    QualityDesc = 'QualityDesc',
    MaterialityScore = 'MaterialityScore',
  }

  const [sortBy, setSortBy] = useState<SortBy>(SortBy.QualityDesc);
  const sortByItems: StringDropdownItem[] = [
    { id: SortBy.QualityDesc, label: 'Qualité (desc)' },
    { id: SortBy.QualityAsc, label: 'Qualité (asc)' },
    { id: SortBy.MaterialityScore, label: 'Score de matérialité' },
  ];

  const [filterByPillarId, setFilterByPillarId] = useState<string | null>(null);

  const stakesMaterialities: TStakeMateriality[] = stakes.map((stake) => {
    const impact = stake.impactMaterialityScore || 0;
    const financial = stake.financialMaterialityScore || 0;
    return {
      stake,
      xMateriality: financial,
      yMateriality: impact,
      materialityScore: Math.max(impact, financial),
    };
  });

  const stakeQualityMap =
    currentProject?.enterprise?.id &&
    isProjectIdOnWrongAlgorithm(currentProject.enterprise.id)
      ? buggyStakeQualityMap(answers)
      : fixedStakeQualityMap(answers);

  // Build an array of {stakeId, quality average} sorted by quality average
  const stakeQualityAverageMap: StakeQualityMapType = Object.keys(
    stakeQualityMap,
  ).reduce((acc: StakeQualityMapType, stakeId) => {
    acc[stakeId] = {
      sum: stakeQualityMap[stakeId].sum / stakeQualityMap[stakeId].count,
      count: 1,
    };
    return acc;
  }, {});

  const stakeQualityAverageArray: StakeQualityAverageType[] = Object.keys(
    stakeQualityAverageMap,
  )
    .map((stakeId) => {
      const stake = stakes.find((stake) => stake.id === stakeId);
      return {
        stake: stake,
        qualityAverage: stakeQualityAverageMap[stakeId].sum,
      };
    })
    .filter((stakeQualityAverage) => {
      if (stakeQualityAverage.stake && filterByPillarId) {
        return (
          stakeQualityAverage.stake.pillar?.id === filterByPillarId || false
        );
      }
      return true;
    })
    .toSorted((a, b) => {
      switch (sortBy) {
        case SortBy.QualityDesc:
          return b.qualityAverage - a.qualityAverage;
        case SortBy.QualityAsc:
          return a.qualityAverage - b.qualityAverage;
        case SortBy.MaterialityScore: {
          // Sort by materiality score from stakesMaterialities
          const aMaterialityScore = stakesMaterialities.find(
            (s) => s.stake.id === a.stake?.id,
          )?.materialityScore;
          const bMaterialityScore = stakesMaterialities.find(
            (s) => s.stake.id === b.stake?.id,
          )?.materialityScore;
          return (bMaterialityScore || 0) - (aMaterialityScore || 0);
        }
        default:
          return 0;
      }
    });

  // Display stake -> qualityBarChart
  return (
    <div className="space-y-1 w-full">
      <div className="flex items-center gap-4 justify-end pb-2">
        <div className="flex items-center gap-2 w-1/4">
          <label className="form-input-label">Trier par</label>
          <StringDropdown
            availableItems={sortByItems}
            item={
              sortByItems.find((item) => item.id === sortBy) || sortByItems[0]
            }
            setItem={(item: StringDropdownItem | null) =>
              setSortBy(item?.id as SortBy)
            }
          />
        </div>
        <div className="flex items-center gap-2">
          <label className="form-input-label">Filtrer par</label>
          <PillarDropdown
            currentPillarId={filterByPillarId}
            setPillarId={setFilterByPillarId}
            isFilter={true}
          />
        </div>
        <ExportMatrixContentToXlsButton
          stakeQualityArray={stakeQualityAverageArray}
          materialityType={materialityType}
        />
      </div>
      {stakeQualityAverageArray.map((stakeQualityAverage) => {
        //find stake
        return (
          <div
            className="flex flex-row items-center gap-2 w-full"
            key={stakeQualityAverage?.stake?.id}
          >
            <div className="flex items-center gap-2 w-1/3">
              <StakeTag stake={stakeQualityAverage.stake} />
            </div>
            <div className="w-2/3">
              <QualityBarChart value={stakeQualityAverage.qualityAverage} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

function fixedStakeQualityMap(
  answers: StakesQualityChart_AnswerFragment[],
): StakeQualityMapType {
  return (answers || [])
    .flatMap((answer) => answer.stakes)
    .reduce((acc: StakeQualityMapType, stakeAnswer) => {
      const stakeId: string = stakeAnswer?.stakeId || '';
      const level = mapQualityLevelToNumber(stakeAnswer?.quality);
      if (acc[stakeId]) {
        if (level || level === 0) {
          acc[stakeId].sum += level;
          acc[stakeId].count++;
        } else {
          // If the level is not defined, we don't increment
        }
      } else {
        if (level || level === 0) {
          acc[stakeId] = { sum: level, count: 1 };
        }
      }

      return acc;
    }, {});
}

// This algorithm is buggy, since it counts all answers, including people who did not answer this specific question.
function buggyStakeQualityMap(
  answers: StakesQualityChart_AnswerFragment[],
): StakeQualityMapType {
  return (answers || [])
    .flatMap((answer) => answer.stakes)
    .reduce((acc: StakeQualityMapType, stakeAnswer) => {
      const stakeId: string = stakeAnswer?.stakeId || '';
      const level = mapQualityLevelToNumber(stakeAnswer?.quality) || 0;
      if (acc[stakeId]) {
        acc[stakeId].sum += level;
        acc[stakeId].count++;
      } else {
        acc[stakeId] = { sum: level, count: 1 };
      }

      return acc;
    }, {});
}

function isProjectIdOnWrongAlgorithm(projectId: string): boolean {
  return listOfProjectIdsOnWrongAlgorithm.includes(projectId);
}

// We keep the wrong algorithm for historical projects
const listOfProjectIdsOnWrongAlgorithm = [
  'c5e911df-8208-4fa8-a1cd-bbcf5c3fb55b', // La Compagnie Fruitière
  '838d9438-e6da-4c2e-8cb0-abab53569803', // AN
  '44ec1e51-3166-4168-a31b-c19bb13e95ca', // UTOPIES (test)
  '892df59a-e64d-4f1f-82ad-dfdab8072a9d', // Ecosystem
  '46bc17ee-028e-4a30-8e74-5b40dbf1fd9d', // La Poste - Services numériques - en cours
  '0728a3eb-8c8a-44e4-8e4b-e540874433c7', // La Poste - Distribution
  '8fc869aa-e090-4352-9f28-018b0f5a5a37', // La Poste - Services numériques
  '7ce8c710-34af-4f5c-ad49-c4fd4f70c6d3', // La Poste - Transports & Logistique
  'e78a8c22-cfce-40b1-a9b3-39bdf023a8f7', // France Médias Monde
  'c4a73672-3747-493c-af4b-750a7bb18d79', // Tessi
  'b3bdf10b-c8ab-4a89-942f-7bab5a2bb1b3', // La Poste - Services de proximité
  '765f3cb3-6181-4245-a3a2-b29e9d0c508a', // La Poste - Services de Proximité
  'b0d6b79d-f947-4c44-be42-b34a57e208c0', // La Poste - Services Numériques
  '4ec8a624-13e0-450e-bfd4-ff98236a235c', // La Poste - Groupe OLD
  '2c466c76-87b8-4397-96ab-04015ed393c9', // La Poste
  '733dc365-b378-4665-862e-1eddefe8bdda', // La Poste - Proximité
  '8edc5740-010e-421c-aeb0-9ce455a67a69', // LA POSTE - Matrice Reine
  '3e5cfb29-9ec7-4247-8160-b48b76ec3d2c', // La Plateforme Verte
  '73bb3a91-1509-43d4-b662-5bd844026fc1', // La Poste - Réseau de Distribution
  '33bf31e3-1fe7-4a21-ab9a-658106a82ede', // LA POSTE - Matrice Reine
  '6a2e3184-9e5e-4ca2-99b3-bf51d2d2fe7d', // test
  'cdaf7bc1-d423-4b19-bf34-8122e9082604', // ba&sh
  'e6b95109-ef2d-4170-97ba-b4d0e69fb877', // Semmaris
  '349428df-cc68-4d67-940f-3ee0833c5a44', // Test
  '98204c66-19a2-4938-9947-95848a6dff12', // Test
  '81c5574a-b0d7-4c16-b51e-c3de414ec2ef', // CDG
  '4aad5b66-3245-4a0e-ac45-aaaffb06e8a4', // La Poste - Groupe
  '2ad6907a-2832-4829-b18c-afadad2ab151', // Provepharm
  '8296ccdc-9dc3-456b-ade2-36e10329b1cd', // Groupe Maped Juratoys
  '4b18b7d2-b389-48ef-b543-51f44df4e56a', // Klépierre
  '546a74ff-8dd6-4d36-be94-256b9452675d', // OCEA
  'c45d3189-17aa-4099-8b57-9e3e5d03604b', // OCEA
  'a413429c-4800-4893-88e8-4ddf347e52a2', // Cartamundi
  '1ac517ef-2fb7-467b-8435-cc1a2f712619', // Marjane Group
  '37587a16-cd49-45d6-94a4-b81c7c1434f2', // AMAURY GROUP
  '20c7c132-4107-4098-a7b9-b89e1adc59c4', // TMSA
  'f4353bbb-fd5c-4c68-89a5-d890e5be9edd', // SNIAA
  // '33f90a6a-609f-4974-8c73-d9a46c682d35', // Unither Pharmaceuticals -> back to new algorithm (2025-01-24)
  'bfe1cb86-d87d-4693-9e27-32c8cf00d599', // AN
];
