import React from 'react';
import { DisclosureRequirement_ArSubChapterFragment } from '../../../../../graphql/cms/generated';
import { filterEmptyDocuments } from '../../../../cms/utils';
import { BlockWithDatapointsRenderer } from '../BlockWithDatapointsRenderer';
import { BlocksRenderer } from '../../../../cms/renderers/BlocksRenderer';
import { Footnotes } from './Footnotes';

export const ApplicationRequirementSubChapterContent = ({
  subChapter,
}: {
  subChapter: DisclosureRequirement_ArSubChapterFragment;
}) => {
  const footnotes = subChapter.footnotes?.filter(filterEmptyDocuments) || [];
  const datapoints = subChapter.datapoints.filter(filterEmptyDocuments);

  return (
    <div className="space-y-2">
      {datapoints.length > 0 ? (
        <BlockWithDatapointsRenderer
          content={subChapter.content}
          datapoints={datapoints.map((d) => d.slug || '')}
        />
      ) : (
        <BlocksRenderer content={subChapter.content} />
      )}
      {footnotes.length > 0 && <Footnotes footnotes={footnotes} />}
    </div>
  );
};
