import {
  ChecklistDocument,
  GapAnalysisDisclosureRequirement_DisclosureRequirementFragment,
  MoveDirection,
  Survey,
  TaskCard_ReportDatapointFragment,
  TaskCard_TaskFragment,
  TaskStatus,
  useTaskCard_DeleteMutation,
  useTaskCard_MoveMutation,
  useTaskCard_UpdateMutation,
  useTaskCardSurveysQuery,
} from '../../graphql/generated';
import clsx from 'clsx';
import TaskStatusSelect from './TaskStatusSelect';
import React, { useState } from 'react';
import { useModal } from '../layout/Modal';
import { UpdateTaskModal } from './UpdateTaskModal';
import { useToast } from '../layout/Toast';
import { Loader } from '../generic/Loader';
import { ContextualMenu } from '../generic/ContextualMenu';
import { generatePath, NavLink } from 'react-router-dom';
import { SurveyProgressBar } from '../survey/SurveyProgressBar';
import { useProjectContext } from '../../providers/ProjectContextProvider';

import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DeleteIcon,
  DownIcon,
  EditIcon,
  MenuIcon,
  StarIcon,
  UpIcon,
  UserIcon,
} from '../icons';
import { isMaturityEvaluation, isSurvey } from '../../types/survey.types';
import { AppRoutes } from '../../screens/AppRoutes';
import { useReportDrCMSDataAggregator } from '@hooks/useReportDrCMSDataAggregator';
import { Radio, RadioColors } from '../form/Radio';
import { RadioGroup } from '@headlessui/react';
import { DatapointTag_DatapointFragment } from '../../graphql/cms/generated';
import { DatapointGapAnalysisStatusEditor } from '../project/gapAnalysis/disclosureRequirement/datapoint/DatapointGapAnalysisStatusEditor';
import { useTranslation } from '@hooks/useTranslation';
import { useDateTimeFromIsoString } from '@hooks/useDateTimeFromIsoString';

export function TaskCard({ task }: { task: TaskCard_TaskFragment }) {
  return (
    <div className="card py-2 pl-2 pr-4 flex flex-col gap-1 items-start shadow-sm">
      <div className="flex items-center gap-2 justify-between w-full">
        <TaskStatusSelect task={task} />
        <div
          className={clsx(
            'grow',
            task.status === TaskStatus.Done
              ? 'text-gray-500 font-normal'
              : 'font-semibold',
          )}
        >
          {task.title}
        </div>
        <div className="flex items-center gap-2">
          <EditTaskMenu task={task} />
          {task.linkToPage && (
            <NavLink
              to={task.linkToPage}
              className="button primary purple px-2 w-8 h-8"
            >
              <ChevronRightIcon className="w-8 h-8" />
            </NavLink>
          )}
        </div>
      </div>

      <div className="pl-12 w-full flex flex-col gap-2 justify-center">
        {task.description && task.status !== TaskStatus.Done && (
          <TaskDescription task={task} />
        )}

        <div className="divide-y divide-gray-100">
          {task.linkToSurvey && <TaskSurvey task={task} />}
          {task.linkToPage === AppRoutes.Survey && <TaskSurveys />}
          {task.linkToReportDisclosureRequirement && (
            <TaskDisclosureRequirement task={task} />
          )}
          {task.status !== TaskStatus.Done &&
            task.subtasks &&
            task.subtasks.length > 0 && (
              <div className="pl-0">
                <TaskSubtasks task={task} />
              </div>
            )}
        </div>

        {task.assignedTo ||
        task.deliverable ||
        task.startedAt ||
        task.finishedAt ? (
          <TaskMetadata task={task} />
        ) : null}
      </div>
    </div>
  );
}

function TaskSubtasks({ task }: { task: TaskCard_TaskFragment }) {
  const [updatingIndex, setUpdatingIndex] = useState<number | null>(null);
  const [updateTaskMutation] = useTaskCard_UpdateMutation();

  const checkSubtask = (indexToCheck: number) => {
    setUpdatingIndex(indexToCheck);
    updateTaskMutation({
      variables: {
        input: {
          id: task.id,
          subtasks: (task.subtasks || []).map((subtask, index) => {
            return index === indexToCheck
              ? { ...subtask, done: !subtask.done }
              : subtask;
          }),
        },
      },
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setUpdatingIndex(null));
  };

  return (
    <div className="flex flex-col gap-2 py-2">
      {(task.subtasks || []).map((subtask, index) => (
        <div key={`subtask_${index}`} className="flex items-center gap-2">
          {updatingIndex === index ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              className="form-input-checkbox border-2 rounded-md w-5 h-5 cursor-pointer"
              checked={subtask.done || false}
              onChange={() => checkSubtask(index)}
            />
          )}
          <div className="text-gray-500">{subtask.title}</div>
        </div>
      ))}
    </div>
  );
}

function TaskDescription({ task }: { task: TaskCard_TaskFragment }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const limit = 170; // ~ 2 lines
  const description = task.description || '';
  const isLongDescription = description.length > limit;
  const shortDescription = description.slice(0, limit);

  return (
    <div>
      <div className="text-gray-500 text-base">
        {isLongDescription ? (
          <div className="space-y-2">
            <div>{isExpanded ? description : `${shortDescription}...`}</div>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="tertiary text-sm p-0"
            >
              {isExpanded ? 'Réduire' : 'Voir plus'}
              <ChevronDownIcon className={isExpanded ? 'rotate-180' : ''} />
            </button>
          </div>
        ) : (
          description
        )}
      </div>
    </div>
  );
}

function TaskSurveys() {
  // Retrieve all stakeholder surveys to display their progress
  const projectContext = useProjectContext();
  const { data, loading } = useTaskCardSurveysQuery({
    variables: {
      companyId: projectContext?.enterprise?.id || '',
    },
    fetchPolicy: 'network-only',
  });
  if (loading) return <Loader />;
  if (!data) return null;
  const stakeholderSurveys = data.surveys;

  return (
    <div className="space-y-1 py-2">
      {stakeholderSurveys.map((survey) => (
        <div key={survey.id}>
          <NavLink
            to={generatePath(AppRoutes.SurveyItem, {
              surveyId: survey.id,
            })}
            key={survey.id}
          >
            <div className="text-gray-500 flex items-center justify-between gap-4 w-full">
              <div className="w-6/12 text-sm truncate text-ellipsis">
                {survey.name}
              </div>
              <div className="grow">
                <SurveyProgressBar survey={survey} />
              </div>
              <ChevronRightIcon />
            </div>
          </NavLink>
        </div>
      ))}
    </div>
  );
}

function TaskSurvey({ task }: { task: TaskCard_TaskFragment }) {
  if (!task.linkToSurvey) return null;
  const survey = task.linkToSurvey;
  const surveyName = survey.name;

  if (isSurvey(survey)) {
    return (
      <div className="py-2">
        <NavLink
          to={generatePath(AppRoutes.SurveyItem, {
            surveyId: survey.id,
          })}
        >
          <div className="text-gray-500 flex items-center justify-between gap-4 w-full">
            <div className="w-6/12 text-sm truncate text-ellipsis">
              {surveyName}
            </div>
            <div className="grow">
              <SurveyProgressBar survey={survey as Survey} />
            </div>
            <ChevronRightIcon />
          </div>
        </NavLink>
      </div>
    );
  }

  if (isMaturityEvaluation(survey)) {
    return (
      <div className="py-2">
        <NavLink
          to={generatePath(AppRoutes.MaturityEvaluations)}
          className="button tertiary"
        >
          <div className="text-gray-500 flex items-center justify-between gap-4 w-full">
            <div className="w-6/12 text-sm truncate text-ellipsis">
              {surveyName}
            </div>
            <div className="grow"></div>
            <ChevronRightIcon />
          </div>
        </NavLink>
      </div>
    );
  }

  return null;
}

function TaskDisclosureRequirement({ task }: { task: TaskCard_TaskFragment }) {
  const modal = useModal();
  const { t } = useTranslation();
  const drAggregator = useReportDrCMSDataAggregator({
    reportId: task.taskGroup.checklist.report?.id || '',
  });
  const drAggregate = drAggregator.find(
    (aggregate) =>
      aggregate.reportDr?.id === task.linkToReportDisclosureRequirement?.id,
  );
  if (!task.linkToReportDisclosureRequirement) return null;

  function openDataPointEditor(
    dataPoint: DatapointTag_DatapointFragment,
    reportDatapoint: TaskCard_ReportDatapointFragment,
    disclosureRequirement: GapAnalysisDisclosureRequirement_DisclosureRequirementFragment,
  ) {
    return modal.openModalWithComponent(
      <DatapointGapAnalysisStatusEditor
        datapoint={dataPoint}
        reportDisclosureRequirement={disclosureRequirement}
        reportDatapoint={reportDatapoint}
      ></DatapointGapAnalysisStatusEditor>,
      drAggregate?.cmsDr?.code,
      false,
      true,
      'w-1/3',
    );
  }

  if (!drAggregate?.cmsTopic) return <Loader />;

  return (
    <div>
      <div className="w-full">
        <div className="font-semibold">
          {drAggregate?.cmsTopic?.name} - {drAggregate?.cmsDr?.code} -{' '}
          {drAggregate?.cmsDr?.title}
        </div>
        {drAggregate?.dataPointAggregates.length === 0 && (
          <div className="italic text-gray-500 text-sm">
            {t('global:noResult')}
          </div>
        )}
        <div className="mt-1 flex flex-col text-gray-500">
          {drAggregate?.dataPointAggregates
            .filter((dpAggregate) => !dpAggregate.reportDataPoint?.disabled)
            .toSorted((a, b) =>
              (a.cmsDataPoint?.paragraph || '').localeCompare(
                b.cmsDataPoint?.paragraph || '',
              ),
            )
            .map((dataPointAggregate) => (
              <div className={clsx('py-1 text-gray-500 cursor-pointer')}>
                <div
                  onClick={() =>
                    dataPointAggregate.reportDataPoint &&
                    drAggregate.cmsDr &&
                    openDataPointEditor(
                      dataPointAggregate.cmsDataPoint!,
                      dataPointAggregate.reportDataPoint as TaskCard_ReportDatapointFragment,
                      drAggregate.reportDr!,
                    )
                  }
                  className="text-base flex"
                >
                  <div className="flex gap-1 items-center text-sm w-full">
                    <RadioGroup value={'true'}>
                      <Radio
                        color={RadioColors.Green}
                        isStatus={true}
                        disabled={true}
                        value={`${dataPointAggregate?.reportDataPoint?.isAvailable}`}
                      />
                    </RadioGroup>
                    <div className="font-semibold shrink-0">
                      {drAggregate?.cmsDr?.code} -{' '}
                      {dataPointAggregate.cmsDataPoint?.paragraph?.replaceAll(
                        ' ',
                        '',
                      )}
                    </div>
                    <div className="truncate grow">
                      &#8210; {dataPointAggregate.cmsDataPoint?.name}
                    </div>
                  </div>
                </div>
                <div className="grow"></div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function TaskMetadata({ task }: { task: TaskCard_TaskFragment }) {
  const { t } = useTranslation();
  const startedAt = useDateTimeFromIsoString(task.startedAt);
  const finishedAt = useDateTimeFromIsoString(task.finishedAt);

  return (
    <div className="flex divide-x divide-gray-100 -ml-4 mt-2">
      {task.assignedTo && (
        <div className="text-xs text-gray-500 flex items-center px-4 gap-2">
          <UserIcon />
          <p>{task.assignedTo}</p>
        </div>
      )}
      {task.deliverable && (
        <div className="text-xs text-gray-500 flex items-center px-4 gap-2">
          <StarIcon />
          <p>
            {t('gap_analysis.checklist.update_task_modal.deliverable')} :{' '}
            {task.deliverable}
          </p>
        </div>
      )}
      {(task.startedAt || task.finishedAt) && (
        <div className="text-xs text-gray-500 flex items-center px-4 gap-2">
          <CalendarIcon />
          <p>
            {task.startedAt ? startedAt.date : '?'} -&gt;{' '}
            {task.finishedAt ? finishedAt.date : '?'}
          </p>
        </div>
      )}
    </div>
  );
}

function EditTaskMenu({ task }: { task: TaskCard_TaskFragment }) {
  const modal = useModal();
  const toast = useToast();

  const [moveTask] = useTaskCard_MoveMutation({
    refetchQueries: [
      {
        query: ChecklistDocument,
        variables: {
          id: task.taskGroup.checklist.id,
        },
      },
    ],
  });
  const [isMoving, setIsMoving] = useState(false);
  const move = (direction: MoveDirection) => {
    setIsMoving(true);
    moveTask({
      variables: {
        input: {
          id: task.id,
          taskGroup: task.taskGroup.id,
          direction: direction,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('La tâche a bien été déplacée');
        modal.closeModal();
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
        console.error(err);
      })
      .finally(() => setIsMoving(false));
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteTaskMutation] = useTaskCard_DeleteMutation();
  const deleteTask = () => {
    setIsDeleting(true);
    deleteTaskMutation({ variables: { id: task.id } })
      .then(() => {
        toast.openToastWithMessage('Tâche supprimée');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsDeleting(false));
  };

  function openUpdateTaskModal() {
    modal.openModalWithComponent(
      <UpdateTaskModal task={task} />,
      'Modifier la tâche',
    );
  }

  const moveUp = () => {
    move(MoveDirection.Up);
  };
  const moveDown = () => {
    move(MoveDirection.Down);
  };

  return (
    <ContextualMenu
      button={<MenuIcon className="flex-shrink-0 h-3.5 w-3.5 text-gray-500" />}
    >
      <button className="contextual-menu-item" onClick={openUpdateTaskModal}>
        <EditIcon />
        <span>Modifier</span>
      </button>
      <button
        className="contextual-menu-item"
        onClick={moveUp}
        disabled={task.position === 0}
      >
        {isMoving ? <Loader /> : <UpIcon />}
        <span>Monter</span>
      </button>
      <button className="contextual-menu-item" onClick={moveDown}>
        {isMoving ? <Loader /> : <DownIcon />}
        <span>Descendre</span>
      </button>
      <button className="contextual-menu-item" onClick={deleteTask}>
        {isDeleting ? <Loader /> : <DeleteIcon />}
        <span>Supprimer</span>
      </button>
    </ContextualMenu>
  );
}
