import { BaseCompany, CompanyType } from '../graphql/generated';

export function isEnterprise<T extends Partial<BaseCompany>>(
  company?: Partial<BaseCompany> | null | undefined,
): company is T {
  return (
    (company as { __typename?: string })?.__typename?.toUpperCase() ===
    CompanyType.Enterprise.toUpperCase()
  );
}
