import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  useGapAnalysisSynthesis_RequirementsQuery,
  UseGapAnalysisTopicDrTableColumns_DisclosureRequirementFragment,
} from '../../../../graphql/cms/generated';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { getNumberOfDatapoints } from '../summary/useGapAnalysisTopicDRTableColumns';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  GapAnalysisSynthesisFragment,
  StandardEnum,
  SurveyLanguage,
  useGapAnalysisSynthesisQuery,
} from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { SelectedDatapointsVsTotalDatapointsDoughnut } from './SelectedDatapointsVsTotalDatapointsDoughnut';
import { AvailableDatapointsVsSelectedDatapointsDoughnut } from './AvailableDatapointsVsSelectedDatapointsDoughnut';
import { VoluntaryDatapointsVsMandatoryDatapointsDoughnut } from './VoluntaryDatapointsVsNotVoluntaryDatapointsDoughnut';

export function GapAnalysisSynthesis() {
  const context = useProjectContext();

  const apiQuery = useGapAnalysisSynthesisQuery({
    variables: {
      input: {
        company: { id: context?.enterprise?.id! },
        standard: StandardEnum.Csrd,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (apiQuery.loading) {
    return (
      <div className="px-16 py-8 main-content">
        <LoaderFullscreen />
      </div>
    );
  }

  if (apiQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {apiQuery.error.message}
      </MessageBox>
    );
  }

  if (apiQuery.data?.companyReport) {
    return <GapAnalysisSynthesisCharts report={apiQuery.data.companyReport} />;
  }
}

function GapAnalysisSynthesisCharts({
  report,
}: {
  report: GapAnalysisSynthesisFragment;
}) {
  const { t } = useTranslation();

  // Get all DR slugs to query CMS
  const disclosureRequirementSlugs = report.topics
    .flatMap((topic) => topic.disclosureRequirements.map((dr) => dr))
    .filter((dr) => !dr.disabled)
    .map((dr) => dr.standardItemLink.slug);

  const requirementsQuery = useGapAnalysisSynthesis_RequirementsQuery({
    client: cmsClient,
    variables: {
      locale: SurveyLanguage.Fr, // No EN translation available yet
      slugs: disclosureRequirementSlugs,
    },
  });
  const requirements: (UseGapAnalysisTopicDrTableColumns_DisclosureRequirementFragment | null)[] =
    requirementsQuery.data?.disclosureRequirements || [];

  const datapointCounters = countTotalAndVoluntaryDataPoints(requirements);

  return (
    <div className="py-8 grid grid-cols-1 xl:grid-cols-2 gap-4 main-content">
      <div className="bg-gray-50 rounded-xl p-8 items-center gap-8 space-y-4">
        <h6 className="text-center">
          {t('gap_analysis.synthesis.selectedDatapointsOverTotalChart.title')}
        </h6>
        <SelectedDatapointsVsTotalDatapointsDoughnut
          selectedDatapointsCount={
            report.gapAnalysisProgress?.selectedDatapoints || 0
          }
          totalDatapointsCount={datapointCounters.total}
        />
      </div>
      <div className="bg-gray-50 rounded-xl p-8 items-center gap-8 space-y-4">
        <h6 className="text-center">
          {t(
            'gap_analysis.synthesis.availableDatapointsOverSelectedChart.title',
          )}
        </h6>
        <AvailableDatapointsVsSelectedDatapointsDoughnut
          availableDatapointsCount={
            report.gapAnalysisProgress?.availableDatapoints || 0
          }
          selectedDatapointsCount={
            report.gapAnalysisProgress?.selectedDatapoints || 0
          }
        />
      </div>
      <div className="bg-gray-50 rounded-xl p-8 items-center gap-8 space-y-4">
        <h6 className="text-center">
          {t(
            'gap_analysis.synthesis.voluntaryDatapointsOverUnvoluntaryChart.title',
          )}
        </h6>
        <VoluntaryDatapointsVsMandatoryDatapointsDoughnut
          voluntaryDatapointsCount={datapointCounters.voluntary}
          mandatoryDatapointsCount={datapointCounters.notVoluntary}
        />
      </div>
    </div>
  );
}

function countTotalAndVoluntaryDataPoints(
  requirements: (UseGapAnalysisTopicDrTableColumns_DisclosureRequirementFragment | null)[],
) {
  const counter = { total: 0, voluntary: 0, notVoluntary: 0 };
  return requirements.reduce(
    (acc: { total: number; voluntary: number; notVoluntary: number }, dr) => {
      if (dr) {
        const oneDatapointCounter = countVoluntaryDatapoint(dr);
        acc.notVoluntary += oneDatapointCounter.notVoluntary;
        acc.voluntary += oneDatapointCounter.voluntary;
        acc.total += getNumberOfDatapoints(dr);
      }
      return acc;
    },
    counter,
  );
}

export function countVoluntaryDatapoint(
  disclosureRequirement: UseGapAnalysisTopicDrTableColumns_DisclosureRequirementFragment,
): {
  voluntary: number;
  notVoluntary: number;
} {
  const counters = { voluntary: 0, notVoluntary: 0 };

  function incrementCounters(
    datapoints:
      | (
          | {
              voluntary?: boolean | null | undefined;
            }
          | undefined
          | null
        )[]
      | undefined
      | null,
  ) {
    datapoints?.forEach((dp) => {
      if (dp) {
        if (dp.voluntary) {
          counters.voluntary++;
        } else {
          counters.notVoluntary++;
        }
      }
    });
  }

  if (disclosureRequirement?.application_requirements) {
    disclosureRequirement.application_requirements.forEach((req) => {
      incrementCounters(req?.datapoints);
    });
  }

  if (disclosureRequirement?.minimum_disclosure_requirements) {
    disclosureRequirement.minimum_disclosure_requirements.forEach((minDR) => {
      if (minDR?.content) {
        incrementCounters(minDR.content.datapoints);
      }
    });
  }

  if (disclosureRequirement?.content) {
    disclosureRequirement.content.dr_level_2?.forEach((level2) => {
      incrementCounters(level2?.datapoints);
      if (level2?.dr_level_3) {
        level2.dr_level_3.forEach((level3) => {
          incrementCounters(level3?.datapoints);
        });
      }
    });
  }
  return counters;
}
