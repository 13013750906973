import React, { ReactNode } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';

import clsx from 'clsx';
import {
  getDateFromISOString,
  getTimeFromISOString,
} from '../../../../services/DateService';
import { UnknownRespondentIcon } from '../UnknownRespondentIcon';

export type SurveyResponseRow = {
  email: {
    inContactList: boolean;
    email?: string | null;
    name?: string | null;
  };
  name?: string | null;
  segmentId: ReactNode;
  responded?: boolean | null;
  submittedAt: {
    date: string | null;
    actions: ReactNode;
  };
};

export const useSurveyResponsesColumns = (): Array<
  ColumnDef<SurveyResponseRow>
> => {
  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper<SurveyResponseRow>();

  return [
    columnHelper.accessor('email', {
      cell: (data) => {
        const { email, name, inContactList } = data.getValue();
        return (
          <div className="flex items-center gap-2">
            {!inContactList && (
              <UnknownRespondentIcon respondentName={name ?? ''} />
            )}
            <div className="font-bold">
              {email || (
                <div className="italic text-gray-500">
                  {t('survey.respondents.unknown')}
                </div>
              )}
            </div>
          </div>
        );
      },
      header: () => <span>{t('survey.respondents.table.email')}</span>,
    }),
    columnHelper.accessor('name', {
      cell: (data) => {
        return (
          data.getValue() || (
            <div className="italic text-gray-500">
              {t('survey.respondents.unknown')}
            </div>
          )
        );
      },
      header: () => <span>{t('survey.respondents.table.name')}</span>,
    }),
    columnHelper.accessor('segmentId', {
      cell: (data) => {
        return (
          <div className="flex justify-between">
            <div className={clsx('flex gap-3 text-gray-900')}>
              {data.getValue()}
            </div>
          </div>
        );
      },
      header: () => <span>{t('survey.respondents.table.segmentId')}</span>,
    }),
    columnHelper.accessor('submittedAt', {
      cell: (data) => {
        const { date, actions } = data.getValue();
        return (
          <div className={'flex justify-between'}>
            {date &&
              getDateFromISOString(date, i18n.language)
                .concat(' ')
                .concat(getTimeFromISOString(date, i18n.language))}
            {actions}
          </div>
        );
      },
      header: () => <span>{t('survey.respondents.table.responseDate')}</span>,
    }),
  ] as Array<ColumnDef<SurveyResponseRow>>;
};
