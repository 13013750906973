import { useTranslation } from '@hooks/useTranslation';
import { NoData } from '../../../generic/NoData';
import { ProgressDoughnutChart } from '../../../generic/chart/ProgressDoughnutChart';
import React from 'react';

export function SelectedDatapointsVsTotalDatapointsDoughnut({
  selectedDatapointsCount,
  totalDatapointsCount,
}: {
  selectedDatapointsCount: number;
  totalDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (totalDatapointsCount === 0 && selectedDatapointsCount === 0) {
    return <NoData text={t('global:noResult')} actions={[]}></NoData>;
  }

  return (
    <ProgressDoughnutChart
      progress={selectedDatapointsCount}
      total={totalDatapointsCount}
      progressLabel={t(
        'gap_analysis.synthesis.selectedDatapointsOverTotalChart.selectedDatapoints',
      )}
      totalLabel={t(
        'gap_analysis.synthesis.selectedDatapointsOverTotalChart.totalDatapoints',
      )}
      title={t('gap_analysis.synthesis.selectedDatapointsOverTotalChart.title')}
    />
  );
}
