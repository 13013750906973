import { PageTitle } from 'components/nav/PageTitle';
import {
  AdminCompaniesScreen_CompanyFragment,
  AdminCompaniesScreen_EmployeeFragment,
  AdminCompaniesScreen_ProjectFragment,
  AdminUserDocument,
  AdminUserFieldsFragment,
  AuthRoles,
  useAdminCompaniesScreen_DeleteProCompanyMutation,
  useAdminCompaniesScreenQuery,
  useAdminUserQuery,
  useAuthUpdateMutation,
  useCompanyUpdateMutation,
} from '../../../graphql/generated';
import { NumberCircle } from '../../../components/generic/NumberCircle';
import { useModal } from '../../../components/layout/Modal';
import React, { useState } from 'react';
import clsx from 'clsx';
import { Loader } from '../../../components/generic/Loader';
import { useToast } from '../../../components/layout/Toast';
import { Toggle } from '../../../components/generic/form/Toggle';
import { TooltipWrapper } from '../../../components/generic/TooltipWrapper';
import { ConsultingCompanyMaxProjectsCountProgressBar } from '../../../components/project/project/ConsultingCompanyMaxProjectsCountProgressBar';
import { ChevronRightIcon } from '../../../components/icons';
import { withAdminNavigation } from '../withAdminNavigation';
import { AdminNavRoutes } from '../AdminNav';
import { useTranslation } from '@hooks/useTranslation';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { CopyTextToClipboardButton } from '../../../components/generic/CopyTextToClipboardButton';
import { getInvitationLink } from '../../../services/CompanyService';
import { RegisterNewProModal } from './RegisterNewProModal';
import { TableSkeletonLoader } from '../../../components/generic/loader/TableSkeletonLoader';
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal';
import { generatePath, Link } from 'react-router-dom';
import { getDateFromISOString } from '../../../services/DateService';
import { useDateTimeFromIsoString } from '@hooks/useDateTimeFromIsoString';

function AdminCompanies() {
  const { t } = useTranslation();
  const query = useAdminCompaniesScreenQuery();
  const modal = useModal();

  const openRegisterNewProModal = () => {
    modal.openModalWithComponent(<RegisterNewProModal />, 'Ajouter un pro');
  };

  const refetch = () => {
    query.refetch().catch((err) => console.error(err));
  };

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <>
      <PageTitle title="Admin" />
      <div className="main-content py-8 w-full space-y-8">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h1>{t('admin:companies.list.title')}</h1>
            <button
              className="primary purple"
              onClick={openRegisterNewProModal}
            >
              {t('global:add')}
            </button>
          </div>
          <CompaniesStats companies={query.data?.consultingCompanies} />
          {query.data && (
            <CompaniesList
              companies={query.data?.consultingCompanies}
              refetch={refetch}
            />
          )}
        </div>
      </div>
    </>
  );
}

function CompaniesStats({
  companies,
}: {
  companies?: AdminCompaniesScreen_CompanyFragment[];
}) {
  const { t } = useTranslation();
  if (!companies) {
    return <TableSkeletonLoader columns={5} rows={20} />;
  }

  const projectsCount = companies.reduce(
    (acc, company) => acc + (company.projects?.length || 0),
    0,
  );
  const usersCount = companies.reduce(
    (acc, company) => acc + (company.employees?.length || 0),
    0,
  );
  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="border-2 border-gray-900 rounded-xl p-4 flex justify-between gap-4 font-bold">
        <div>{t('admin:companies.stats.companies')}</div>
        <div>{companies.length}</div>
      </div>
      <div className="border-2 border-gray-900 rounded-xl p-4 flex justify-between gap-4 font-bold">
        <div>{t('admin:companies.stats.projects')}</div>
        <div>{projectsCount}</div>
      </div>
      <div className="border-2 border-gray-900 rounded-xl p-4 flex justify-between gap-4 font-bold">
        <div>{t('admin:companies.stats.users')}</div>
        <div>{usersCount}</div>
      </div>
    </div>
  );
}

function CompaniesList({
  companies,
  refetch,
}: {
  companies: AdminCompaniesScreen_CompanyFragment[];
  refetch: () => void;
}) {
  return (
    <ul className="space-y-4">
      {companies.map((company, index) => (
        <CompanyItem
          company={company}
          index={index}
          key={company.id}
          refetch={refetch}
        />
      ))}
    </ul>
  );
}

function CompanyItem({
  company,
  index,
  refetch,
}: {
  company: AdminCompaniesScreen_CompanyFragment;
  index: number;
  refetch: () => void;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const [isExpanded, setIsExpanded] = useState(false);
  const projects: AdminCompaniesScreen_ProjectFragment[] =
    company.projects || [];
  const employeeInvitationLink = getInvitationLink(company.id, null);

  return (
    <li className="flex items-center gap-2">
      <NumberCircle number={index + 1} size={8} />
      <div
        className={clsx(
          'border border-gray-100 rounded-xl space-y-6 w-full',
          company.name === 'Good Steps' ? 'bg-gray-50' : 'bg-white',
        )}
      >
        <div
          className="p-4 flex items-center gap-4 justify-between cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div>
            <h3 className="font-bold">{company.name}</h3>
            <div className="text-gray-500 text-xs">
              {t('admin:companies.list.item.createdAtAndBy', {
                createdAt: createdAt.date,
                createdBy: `${company.createdBy?.firstName} ${
                  company.createdBy?.lastName
                }`,
              })}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="min-w-[15rem]">
              <ConsultingCompanyMaxProjectsCountProgressBar
                companyId={company.id}
              />
            </div>
            <ChevronRightIcon className={clsx(isExpanded && 'rotate-90')} />
          </div>
        </div>

        {isExpanded && (
          <div className="space-y-4 divide-y">
            <div className="p-4 flex items-start gap-8 w-full divide-x divide-gray-100">
              <div className="w-1/3 space-y-2">
                <div className="flex items-center gap-4 justify-between">
                  <div className="flex items-center gap-1">
                    <h6>{t('admin:companies.list.item.employees')}</h6>
                    <div className="tag gray">{company.employees?.length}</div>
                  </div>
                </div>
                <ul className="space-y-1 text-sm">
                  {company.employees?.map((employee) => (
                    <EmployeeItem key={employee.id} employee={employee} />
                  ))}
                </ul>
                <CopyTextToClipboardButton
                  textToCopy={employeeInvitationLink}
                  buttonLabel={t(
                    'admin:companies.list.item.employee_invitation_link',
                  )}
                  className="small"
                />
              </div>
              <div className="w-2/3 pl-8 space-y-4">
                <CompanyProjects company={company} refetch={refetch} />
                <CompanyTemplates company={company} />
              </div>
            </div>
            <div className="p-4 flex justify-end">
              <DeleteProCompanyButton companyId={company.id} />
            </div>
          </div>
        )}
      </div>
    </li>
  );
}

function CompanyProjects({
  company,
  refetch,
}: {
  company: AdminCompaniesScreen_CompanyFragment;
  refetch: () => void;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const projects: AdminCompaniesScreen_ProjectFragment[] =
    company.projects || [];
  return (
    <>
      <div className="flex items-center justify-between gap-1">
        <div className="flex items-center gap-1">
          <h6>{t('admin:companies.list.item.projects')}</h6>
          <div className="tag gray">{projects.length}</div>
        </div>
        <MaxProjectsCount project={company} />
      </div>
      <ul className="space-y-1 divide-y divide-gray-100 text-sm w-full">
        {projects?.map((project) => {
          const projectInvitationLink = getInvitationLink(
            project.id,
            AuthRoles.Coach,
          );
          return (
            <li
              key={project.id}
              className="flex items-center justify-between gap-2 w-full pt-1"
            >
              <div className="space-y-1">
                <div className="font-bold">{project.name}</div>
                <div className="text-gray-500 text-xs">
                  {t('admin:companies.list.item.createdAtAndBy', {
                    createdAt: createdAt.date,
                    createdBy: `${project.createdBy?.firstName} ${
                      project.createdBy?.lastName
                    }`,
                  })}
                </div>
              </div>
              <div className="flex items-center gap-0.5">
                <DeleteProCompanyButton companyId={project.id} />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function CompanyTemplates({
  company,
}: {
  company: AdminCompaniesScreen_CompanyFragment;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const templates: AdminCompaniesScreen_ProjectFragment[] =
    company.projects?.filter((project) => project.isTemplate) || [];
  return (
    <>
      <div className="flex items-center justify-between gap-1">
        <div className="flex items-center gap-1">
          <h6>{t('admin:companies.list.item.templates')}</h6>
          <div className="tag gray">{templates.length}</div>
        </div>
      </div>
      <ul className="space-y-1 divide-y divide-gray-100 text-sm w-full">
        {templates?.map((project) => {
          const projectInvitationLink = getInvitationLink(
            project.id,
            AuthRoles.Coach,
          );
          return (
            <li
              key={project.id}
              className="flex items-center justify-between gap-2 w-full pt-1"
            >
              <div className="space-y-1">
                <div className="font-bold">{project.name}</div>
                <div className="text-gray-500 text-xs">
                  {t('admin:companies.list.item.created_at')} {createdAt.date}{' '}
                  {t('admin:companies.list.item.created_by')}{' '}
                  {project.createdBy?.firstName} {project.createdBy?.lastName}
                  {!project.createdBy?.firstName &&
                    !project.createdBy?.lastName &&
                    '???'}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function EmployeeItem({
  employee,
}: {
  employee: AdminCompaniesScreen_EmployeeFragment;
}) {
  const { t } = useTranslation();
  const adminUserQuery = useAdminUserQuery({
    variables: {
      id: employee.id,
    },
  });

  if (adminUserQuery.loading) {
    return <Loader />;
  }

  return (
    <li key={employee.id} className="flex items-center gap-2">
      {adminUserQuery?.data?.user.auth && (
        <EmployeeEnabledToggle employee={adminUserQuery?.data?.user} />
      )}
      {adminUserQuery?.data?.user.auth ? (
        <Link
          to={generatePath(AdminNavRoutes.User, {
            id: adminUserQuery?.data?.user.id,
          })}
          className={
            adminUserQuery?.data?.user.auth?.disabled ? 'line-through' : ''
          }
          rel="noreferrer"
        >
          {employee.firstName} {employee.lastName}
        </Link>
      ) : (
        <div>
          {employee.firstName} {employee.lastName}
        </div>
      )}

      {adminUserQuery?.data?.user.auth ? (
        <UserLastSignInDate user={adminUserQuery?.data?.user} />
      ) : (
        <div className="tag red small">
          {t('admin:companies.list.item.unknown')}
        </div>
      )}
    </li>
  );
}

function EmployeeEnabledToggle({
  employee,
}: {
  employee: AdminUserFieldsFragment;
}) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(
    !!employee.auth?.disabled,
  );
  const [disableUserMutation] = useAuthUpdateMutation({
    refetchQueries: () => [
      { query: AdminUserDocument, variables: { id: employee.id } },
    ],
  });
  const setNewState = (newState: boolean) => {
    setIsDisabled(newState);
    disableUserMutation({
      variables: {
        input: {
          id: employee.auth?.id || '',
          disabled: newState,
        },
      },
    })
      .then(() => {
        console.log('User disabled');
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <TooltipWrapper
      label={
        isDisabled
          ? t('admin:companies.list.item.enable')
          : t('admin:companies.list.item.disable')
      }
    >
      <Toggle state={!isDisabled} setState={() => setNewState(!isDisabled)} />
    </TooltipWrapper>
  );
}

function MaxProjectsCount({
  project,
}: {
  project: AdminCompaniesScreen_CompanyFragment;
}) {
  const { t } = useTranslation();
  const [maxProjectsCount, setMaxProjectsCount] = useState<number>(
    project.metadata?.maxProjectsCount || 0,
  );
  const toast = useToast();

  const [updateCompany] = useCompanyUpdateMutation();
  const [isLoading, setIsLoading] = useState(false);
  const saveNewMaxCount = (newMaxCount: number) => {
    setIsLoading(true);
    setMaxProjectsCount(newMaxCount);
    updateCompany({
      variables: {
        input: {
          id: project.id,
          metadata: {
            maxProjectsCount: newMaxCount,
          },
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('admin:companies.toast.limit_updated'));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const isUnlimited =
    maxProjectsCount === 0 ||
    maxProjectsCount === null ||
    maxProjectsCount === undefined;
  return (
    <div className="flex items-center gap-2">
      <label className="form-input-label">
        {t('admin:companies.list.item.max_projects')}
      </label>
      {isUnlimited && (
        <div className="tag red">{t('admin:companies.list.item.infinite')}</div>
      )}
      {isLoading && <Loader />}
      <input
        type="number"
        className="form-input-text w-20"
        disabled={isLoading}
        min={0}
        value={maxProjectsCount}
        onChange={(e) => saveNewMaxCount(parseInt(e.target.value))}
      />
    </div>
  );
}

function UserLastSignInDate({ user }: { user: AdminUserFieldsFragment }) {
  const { t, i18n } = useTranslation();
  const lastLoginDate = user.auth?.lastSignInDate;
  if (!lastLoginDate) {
    return (
      <span className="text-xs text-gray-500 italic">
        {t('admin:companies.list.item.no_login')}
      </span>
    );
  }

  const isMoreThan30DaysAgo =
    new Date(lastLoginDate) < new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  return (
    <div
      className={clsx(
        'text-xs',
        isMoreThan30DaysAgo ? 'text-red-700' : 'text-gray-500',
      )}
    >
      {getDateFromISOString(lastLoginDate, i18n.language)}
    </div>
  );
}

function DeleteProCompanyButton({ companyId }: { companyId: string }) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();
  const [isDeleting, setIsDeleting] = useState(false);

  const [deleteCompanyMutation] =
    useAdminCompaniesScreen_DeleteProCompanyMutation();

  const deleteCompany = () => {
    setIsDeleting(true);
    deleteCompanyMutation({
      variables: {
        companyId: companyId,
      },
      refetchQueries: ['AdminCompaniesScreen'],
    })
      .then(() => {
        toast.openToastWithMessage(t('admin:companies.toast.company_deleted'));
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <button
      className="primary danger small"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.delete.content')}
            callbackIfConfirmed={deleteCompany}
          />,
          t('global:delete'),
        )
      }
    >
      {isDeleting && <Loader />}
      <div>{t('global:delete')}</div>
    </button>
  );
}

export const AdminCompaniesScreen = withAdminNavigation(
  AdminCompanies,
  AdminNavRoutes.Companies,
);
