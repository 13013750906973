import { useTranslation } from '@hooks/useTranslation';
import {
  getDateFromISOString,
  getTimeFromISOString,
} from '../services/DateService';

export type DateTimeFromIsoString = {
  date: string;
  time: string;
};

export const useDateTimeFromIsoString = (
  isoString: string,
): DateTimeFromIsoString => {
  const { i18n } = useTranslation();
  return {
    date: getDateFromISOString(isoString, i18n.language),
    time: getTimeFromISOString(isoString, i18n.language),
  };
};
