import React, { useState } from 'react';
import { useModal } from '../../layout/Modal';
import { UpdateNoteModal } from './UpdateNoteModal';
import {
  Note_NoteContentFragment,
  Note_NoteContextualMenuFragment,
  Note_NoteFragment,
  useNote_NoteDeleteMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { ContextualMenu } from '../../generic/ContextualMenu';
import { Loader } from '../../generic/Loader';
import { InnerHtml } from '../../generic/InnerHtml';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  MenuIcon,
} from '../../icons';
import { useDateTimeFromIsoString } from '@hooks/useDateTimeFromIsoString';

export function Note({ note }: { note: Note_NoteFragment }) {
  const updatedAt = useDateTimeFromIsoString(note.updatedAt);
  const modal = useModal();
  const openNoteModal = () => {
    modal.openModalWithComponent(
      <UpdateNoteModal note={note} />,
      null,
      false,
      false,
      'w-3xl',
      'p-0',
    );
  };
  return (
    <div className="card p-4 flex flex-col gap-1 items-start shadow-sm">
      <div className="flex items-center gap-4 justify-between w-full">
        <div className="grow flex items-center gap-2">
          <div className="font-semibold cursor-pointer" onClick={openNoteModal}>
            {note.title}
          </div>
          <div className="text-gray-500 text-sm">
            Éditée le {updatedAt.date}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <NoteContextualMenu note={note} />
          <button className="primary purple px-2" onClick={openNoteModal}>
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
      <NoteContent note={note} />
    </div>
  );
}

function NoteContent({ note }: { note: Note_NoteContentFragment }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const limit = 170; // ~ 2 lines
  const content = note.content || '';
  const isLongDescription = content.length > limit;
  const shortDescription = content.slice(0, limit);

  return (
    <div>
      <div className="text-gray-500 text-sm">
        {isLongDescription ? (
          <div className="space-y-2">
            <div>
              {isExpanded ? (
                <InnerHtml html={content} />
              ) : (
                <InnerHtml html={shortDescription} />
              )}
            </div>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="tertiary text-sm p-0"
            >
              {isExpanded ? 'Réduire' : 'Voir plus'}
              <ChevronDownIcon className={isExpanded ? 'rotate-180' : ''} />
            </button>
          </div>
        ) : (
          <InnerHtml html={content} />
        )}
      </div>
    </div>
  );
}

function NoteContextualMenu({
  note,
}: {
  note: Note_NoteContextualMenuFragment;
}) {
  const modal = useModal();
  const toast = useToast();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteNoteMutation] = useNote_NoteDeleteMutation();
  const deleteNote = () => {
    setIsDeleting(true);
    deleteNoteMutation({ variables: { id: note.id } })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        toast.openToastWithMessage('Note supprimée');
        modal.closeModal();
      })
      .finally(() => setIsDeleting(false));
  };

  const openNoteModal = () => {
    modal.openModalWithComponent(
      <UpdateNoteModal note={note} />,
      null,
      false,
      false,
      'w-3xl',
      'p-0',
    );
  };

  return (
    <ContextualMenu
      button={<MenuIcon className="flex-shrink-0 h-3.5 w-3.5 text-gray-500" />}
    >
      <button className="contextual-menu-item" onClick={openNoteModal}>
        <EditIcon />
        <span>Modifier</span>
      </button>
      <button className="contextual-menu-item" onClick={deleteNote}>
        {isDeleting ? <Loader /> : <DeleteIcon />}
        <span>Supprimer</span>
      </button>
    </ContextualMenu>
  );
}
