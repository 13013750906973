import { useTranslation } from '@hooks/useTranslation';
import { NoData } from '../../../generic/NoData';
import { ProgressDoughnutChart } from '../../../generic/chart/ProgressDoughnutChart';
import React from 'react';

export function VoluntaryDatapointsVsMandatoryDatapointsDoughnut({
  voluntaryDatapointsCount,
  mandatoryDatapointsCount,
}: {
  voluntaryDatapointsCount: number;
  mandatoryDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (voluntaryDatapointsCount === 0 && mandatoryDatapointsCount === 0) {
    return <NoData text={t('global:noResult')} actions={[]}></NoData>;
  }

  return (
    <ProgressDoughnutChart
      progress={voluntaryDatapointsCount}
      total={mandatoryDatapointsCount}
      progressLabel={t(
        'gap_analysis.synthesis.voluntaryDatapointsOverUnvoluntaryChart.voluntaryDatapoints',
      )}
      totalLabel={t(
        'gap_analysis.synthesis.voluntaryDatapointsOverUnvoluntaryChart.unvoluntaryDatapoints',
      )}
      title={t(
        'gap_analysis.synthesis.voluntaryDatapointsOverUnvoluntaryChart.title',
      )}
    />
  );
}
