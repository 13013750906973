import React from 'react';
import clsx from 'clsx';

export function QualityBarChart({ value }: { value: number }) {
  // bar is from -1 to 1
  const width = ((1 + value) / 2) * 100;
  const boundedWidth = Math.min(Math.max(width, -100), 100);

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col gap-2 relative h-3 grow">
        <div
          className="absolute top-0 left-0 bg-gray-100 rounded-lg flex items-end h-3"
          style={{ width: `100%` }}
        ></div>
        {value < 0 && (
          <div
            className="absolute top-0 bg-red-100 rounded-lg flex items-end h-3"
            style={{
              left: `${boundedWidth}%`,
              width: `${50 - boundedWidth}%`,
            }}
          >
            <div className="rounded-full h-5 w-5 bg-red-500 border-2 border-white absolute left-0 bottom-1/2 top-1/2 -mt-2.5"></div>
          </div>
        )}
        {value > 0 && (
          <div
            className="absolute top-0 bg-green-100 rounded-lg flex items-end h-3"
            style={{
              left: `50%`,
              width: `${boundedWidth - 50}%`,
            }}
          >
            <div className="rounded-full h-5 w-5 bg-green-500 border-2 border-white absolute right-0 bottom-1/2 top-1/2 -mt-2.5"></div>
          </div>
        )}
        {value === 0 && (
          <div className="rounded-full h-5 w-5 bg-purple-500 border-2 border-white absolute left-1/2 -ml-2.5 bottom-1/2 top-1/2 -mt-2.5"></div>
        )}
      </div>
      <div
        className={clsx(
          'w-20 flex items-center justify-end gap-1 font-title title-h1',
          value > 0 && 'text-green-500',
          value < 0 && 'text-red-500',
          value === 0 && 'text-purple-500',
        )}
      >
        <div>{value > 0 && '+'}</div>
        <div>{(Math.round(value * 100) / 100).toFixed(2)}</div>
      </div>
    </div>
  );
}
