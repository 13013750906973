import React, { useState } from 'react';
import { ValueChainItemEnum } from '../../../graphql/generated';
import { StringDropdownItem } from '../../generic/dropdown/StringDropdown.types';
import StringDropdown from '../../generic/dropdown/StringDropdown';
import { useTranslation } from '@hooks/useTranslation';

export function ValueChainItemsDropdown({
  initialValues,
  callback,
}: {
  initialValues: ValueChainItemEnum[];
  callback: (newValues: ValueChainItemEnum[]) => void;
}) {
  const { t } = useTranslation();

  // /!\ Order is important
  const valueChainItemsDropdownItems: StringDropdownItem[] = [
    {
      id: ValueChainItemEnum.Upstream,
      label: t(`enum:valueChainItem.${ValueChainItemEnum.Upstream}`),
    },
    {
      id: ValueChainItemEnum.OwnOperations,
      label: t(`enum:valueChainItem.${ValueChainItemEnum.OwnOperations}`),
    },
    {
      id: ValueChainItemEnum.Downstream,
      label: t(`enum:valueChainItem.${ValueChainItemEnum.Downstream}`),
    },
  ];

  const initialStateValue: StringDropdownItem[] =
    valueChainItemsDropdownItems.filter(
      (valueChainItem: StringDropdownItem) => {
        if (valueChainItem.id !== null) {
          const enumValue = valueChainItem.id as ValueChainItemEnum;
          return initialValues.includes(enumValue);
        }
      },
    );

  const [valueChainItems, setValueChainItems] =
    useState<StringDropdownItem[]>(initialStateValue);

  const pickValues = (values: StringDropdownItem[]) => {
    setValueChainItems(values);
    callback(values.map((value) => value.id as ValueChainItemEnum));
  };

  return (
    <StringDropdown
      availableItems={valueChainItemsDropdownItems}
      item={valueChainItems}
      multiple={true}
      setItem={pickValues}
    />
  );
}
