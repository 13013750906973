import React, { useEffect, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  IroScaleItem,
  scaleCriteriaChoices,
} from '../evaluation/picker/IroScalePicker';
import { GradientTag } from '../../../generic/GradientTag';
import RichTextEditorTextarea from '../../../generic/form/RichTextEditorTextarea';
import {
  IroSettings_ReferentialFragment,
  IroType,
  IrremediabilityValueDefinitionInput,
  LikelihoodValueDefinitionInput,
  ReferentialIroCriteriaValuesDefinitionUpdateInput,
  ScaleValueDefinitionInput,
  ScopeValueDefinitionInput,
  SurveyLanguage,
  useIroSettingsUpdateReferentialMutation,
} from '../../../../graphql/generated';
import {
  IroScopeItem,
  scopeCriteriaChoices,
} from '../evaluation/picker/IroScopePicker';
import {
  IroIrremediabilityItem,
  irremediabilityCriteriaChoices,
} from '../evaluation/picker/IroIrremediabilityPicker';
import {
  IroLikelihoodItem,
  likelihoodCriteriaChoices,
} from '../evaluation/picker/IroLikelihoodPicker';
import { IroTimeHorizonItem } from '../evaluation/picker/IroTimeHorizonPicker';
import { TFunction } from 'i18next';
import { useToast } from '../../../layout/Toast';
import { ChevronDownIcon, ExportIcon } from '../../../icons';
import clsx from 'clsx';
import { IroIcon } from '../IroIcon';
import { iroTypeToTypename } from '../evaluation/row/IroRow';
import { useDebounce } from '@hooks/useDebounce';
import { ApiDownloadRoutes, useDownloadFile } from '@hooks/useDownloadFile';
import { generatePath } from 'react-router-dom';
import { Loader } from '../../../generic/Loader';

type AllCriteriaChoices =
  | IroScaleItem
  | IroScopeItem
  | IroIrremediabilityItem
  | IroLikelihoodItem
  | IroTimeHorizonItem;

type AllCriteriaDefinitionInput =
  | ScaleValueDefinitionInput
  | ScopeValueDefinitionInput
  | LikelihoodValueDefinitionInput
  | IrremediabilityValueDefinitionInput;

enum CriteriaTypes {
  scale = 'scale',
  scope = 'scope',
  irremediability = 'irremediability',
  likelihood = 'likelihood',
}

export const IroSettings = ({
  referential,
}: {
  referential: IroSettings_ReferentialFragment;
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  // Take all props except __typename
  const initialValues = referential.iroCriteriaValuesDefinitions?.map(
    ({ __typename, ...rest }) => rest,
  );
  const [definitions, setDefinitions] = useState<
    ReferentialIroCriteriaValuesDefinitionUpdateInput[]
  >(
    initialValues?.map((definition) => ({
      iroType: definition.iroType,
      irremediabilityValuesDefinitions:
        definition.irremediabilityValuesDefinitions,
      likelihoodValuesDefinitions: definition.likelihoodValuesDefinitions,
      scaleValuesDefinitions: definition.scaleValuesDefinitions,
      scopeValuesDefinitions: definition.scopeValuesDefinitions,
    })) || [],
  );

  const [defineIroCriteriaValues, { loading }] =
    useIroSettingsUpdateReferentialMutation();

  const save = () => {
    defineIroCriteriaValues({
      variables: {
        input: {
          id: referential.id,
          iroCriteriaValuesDefinitions: definitions,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(
          t('toast:iro.criteriaValuesDefinition.success'),
        );
      })
      .catch((err) => {
        console.error(err);
        toast.openToastWithMessage(
          t('toast:iro.criteriaValuesDefinition.error'),
        );
      });
  };

  // Autosave
  const debouncedDefinitions = useDebounce(definitions);
  useEffect(() => {
    if (
      debouncedDefinitions !== null &&
      !loading &&
      JSON.stringify(debouncedDefinitions) !== JSON.stringify(initialValues)
    ) {
      save();
    }
  }, [debouncedDefinitions]);

  const updateDefinition = (iroType: IroType, definition: any) => {
    const existingDefinition = definitions.find((d) => d.iroType === iroType);
    if (existingDefinition) {
      const updatedDefinitions: ReferentialIroCriteriaValuesDefinitionUpdateInput[] =
        definitions.map((d) =>
          d.iroType === iroType ? { ...d, ...definition } : d,
        );
      setDefinitions(updatedDefinitions);
      return;
    } else {
      const updatedDefinitions: ReferentialIroCriteriaValuesDefinitionUpdateInput[] =
        [...definitions, { iroType, ...definition }];
      setDefinitions(updatedDefinitions);
    }
  };

  const { downloadFile } = useDownloadFile({
    errorToastMessage: t('toast:survey.export.error'),
    fileName: `${t('nav:page.referential')}.xlsx`,
    path: generatePath(ApiDownloadRoutes.ReferentialExport, {
      referentialId: referential.id,
    }),
    language: SurveyLanguage.Fr,
  });

  return (
    <div className="space-y-8 main-content">
      <div className="bg-blue-50 p-4 rounded-xl flex items-center justify-between">
        <span className="text-blue-900 text-sm">
          {t('translation:iro.settings.exportReferentialLabel')}
        </span>
        <button
          className="primary blue"
          onClick={downloadFile}
          disabled={loading}
        >
          {loading ? <Loader /> : <ExportIcon />} {t('global:export')}
        </button>
      </div>
      <div className="space-y-2">
        <h4>{t('translation:iro.settings.scaleCriteriaDefinitions')}</h4>
        <div className="divide-y divide-gray-100 ml-4">
          {getDefinitionsByIroTypeAndCriteriaType(definitions).map(
            (iroTypeCriteria) => (
              <div key={iroTypeCriteria.iroType} className="pt-4">
                <div className="flex items-center gap-2">
                  <IroIcon
                    iroTypename={iroTypeToTypename(iroTypeCriteria.iroType)}
                  />
                  <h5>
                    {t(`translation:iro.types.${iroTypeCriteria.iroType}`)}
                  </h5>
                </div>
                <div className="divide-y divide-gray-100 ml-12">
                  {iroTypeCriteria.criteriaTypes.map((criteriaType, index) => {
                    const definition = definitions.find(
                      (d) => d.iroType === iroTypeCriteria.iroType,
                    );
                    return (
                      <IroTypeDefinitionForms
                        key={criteriaType.type}
                        iroType={iroTypeCriteria.iroType}
                        criteriaType={criteriaType.type}
                        definition={
                          definition
                            ? (criteriaType.definitions as AllCriteriaDefinitionInput[])
                            : []
                        }
                        updateDefinition={updateDefinition}
                      />
                    );
                  })}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

function IroTypeDefinitionForms<T extends AllCriteriaDefinitionInput>({
  iroType,
  criteriaType,
  definition,
  updateDefinition,
}: {
  iroType: IroType;
  criteriaType: CriteriaTypes;
  definition: T[];
  updateDefinition: (
    iroType: IroType,
    definition: ReferentialIroCriteriaValuesDefinitionUpdateInput,
  ) => void;
}) {
  const { t } = useTranslation();
  const [
    definitionForThisIroTypeAndCriteria,
    setDefinitionForThisIroTypeAndCriteria,
  ] = useState<T[]>(definition);

  const updateDefinitionForThisIroTypeAndCriteria = (
    value: string,
    definition: string,
  ) => {
    const existingDefinition = definitionForThisIroTypeAndCriteria.find(
      (d) => d.value === value,
    );

    if (existingDefinition) {
      // If yes, we update it
      const updatedDefinition = definitionForThisIroTypeAndCriteria.map((d) =>
        d.value === value ? { ...d, definition } : d,
      );
      setDefinitionForThisIroTypeAndCriteria(updatedDefinition as T[]);
      updateDefinition(
        iroType,
        buildInput(iroType, criteriaType, updatedDefinition as T[]),
      );
      return;
    } else {
      // If not, we add it
      const updatedDefinition = [
        ...definitionForThisIroTypeAndCriteria,
        { value, definition },
      ];

      setDefinitionForThisIroTypeAndCriteria(updatedDefinition as T[]);
      updateDefinition(
        iroType,
        buildInput(iroType, criteriaType, updatedDefinition as T[]),
      );
    }
  };

  const buildInput = (
    iroType: IroType,
    criteriaType: CriteriaTypes,
    definition: T[],
  ): ReferentialIroCriteriaValuesDefinitionUpdateInput => {
    switch (criteriaType) {
      case CriteriaTypes.scale:
        return {
          iroType,
          scaleValuesDefinitions: definition as ScaleValueDefinitionInput[],
        };
      case CriteriaTypes.scope:
        return {
          iroType,
          scopeValuesDefinitions: definition as ScopeValueDefinitionInput[],
        };
      case CriteriaTypes.irremediability:
        return {
          iroType,
          irremediabilityValuesDefinitions:
            definition as IrremediabilityValueDefinitionInput[],
        };
      case CriteriaTypes.likelihood:
        return {
          iroType,
          likelihoodValuesDefinitions:
            definition as LikelihoodValueDefinitionInput[],
        };
    }
  };

  const criteriaChoices = getCriteriaChoices(criteriaType, t);

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="space-y-2">
      <div
        className="flex items-center gap-2 justify-between py-4 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h6>{t(`iro.criteria.${criteriaType}.title`)}</h6>
        <button className="tertiary" onClick={() => setIsExpanded(!isExpanded)}>
          <ChevronDownIcon
            className={clsx('w-4 h-4', isExpanded && 'rotate-180')}
          />
        </button>
      </div>
      {isExpanded && (
        <div className="space-y-8">
          {criteriaChoices.map((choice) => (
            <div className="space-y-2" key={choice.id}>
              <div className="w-fit">
                <GradientTag name={choice.name} strength={choice.strength} />
              </div>
              <RichTextEditorTextarea
                value={
                  definitionForThisIroTypeAndCriteria.find(
                    (d) => d.value === choice.id,
                  )?.definition || ''
                }
                onChange={(value) =>
                  choice.id &&
                  updateDefinitionForThisIroTypeAndCriteria(choice.id, value)
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function getCriteriaChoices(
  criteriaType: CriteriaTypes,
  t: TFunction,
): AllCriteriaChoices[] {
  switch (criteriaType) {
    case CriteriaTypes.scale:
      return scaleCriteriaChoices(t);
    case CriteriaTypes.scope:
      return scopeCriteriaChoices(t);
    case CriteriaTypes.irremediability:
      return irremediabilityCriteriaChoices(t);
    case CriteriaTypes.likelihood:
      return likelihoodCriteriaChoices(t);
  }
}

function getDefinitionsByIroTypeAndCriteriaType(
  definitions: ReferentialIroCriteriaValuesDefinitionUpdateInput[],
) {
  return [
    {
      iroType: IroType.NegativeImpact,
      criteriaTypes: [
        {
          type: CriteriaTypes.scale,
          definitions:
            (
              definitions.find((d) => d.iroType === IroType.NegativeImpact) ||
              {}
            ).scaleValuesDefinitions || [],
        },
        {
          type: CriteriaTypes.scope,
          definitions:
            (
              definitions.find((d) => d.iroType === IroType.NegativeImpact) ||
              {}
            ).scopeValuesDefinitions || [],
        },
        {
          type: CriteriaTypes.irremediability,
          definitions:
            (
              definitions.find((d) => d.iroType === IroType.NegativeImpact) ||
              {}
            ).irremediabilityValuesDefinitions || [],
        },
        {
          type: CriteriaTypes.likelihood,
          definitions:
            (
              definitions.find((d) => d.iroType === IroType.NegativeImpact) ||
              {}
            ).likelihoodValuesDefinitions || [],
        },
      ],
    },
    {
      iroType: IroType.PositiveImpact,
      criteriaTypes: [
        {
          type: CriteriaTypes.scale,
          definitions:
            (
              definitions.find((d) => d.iroType === IroType.PositiveImpact) ||
              {}
            ).scaleValuesDefinitions || [],
        },
        {
          type: CriteriaTypes.scope,
          definitions:
            (
              definitions.find((d) => d.iroType === IroType.PositiveImpact) ||
              {}
            ).scopeValuesDefinitions || [],
        },
        {
          type: CriteriaTypes.likelihood,
          definitions:
            (
              definitions.find((d) => d.iroType === IroType.PositiveImpact) ||
              {}
            ).likelihoodValuesDefinitions || [],
        },
      ],
    },
    {
      iroType: IroType.Risk,
      criteriaTypes: [
        {
          type: CriteriaTypes.scale,
          definitions:
            (definitions.find((d) => d.iroType === IroType.Risk) || {})
              .scaleValuesDefinitions || [],
        },
        {
          type: CriteriaTypes.likelihood,
          definitions:
            (definitions.find((d) => d.iroType === IroType.Risk) || {})
              .likelihoodValuesDefinitions || [],
        },
      ],
    },
    {
      iroType: IroType.Opportunity,
      criteriaTypes: [
        {
          type: CriteriaTypes.scale,
          definitions:
            (definitions.find((d) => d.iroType === IroType.Opportunity) || {})
              .scaleValuesDefinitions || [],
        },
        {
          type: CriteriaTypes.likelihood,
          definitions:
            (definitions.find((d) => d.iroType === IroType.Opportunity) || {})
              .likelihoodValuesDefinitions || [],
        },
      ],
    },
  ];
}
