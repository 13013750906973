import {
  FinancialOpportunityRow_OpportunityIroFragment,
  FinancialRiskRow_RiskIroFragment,
  IroEvaluationHeader_StakeFragment,
  IroType,
  NegativeImpactRow_NegativeImpactIroFragment,
  PositiveImpactRow_PositiveImpactIroFragment,
  SurveyLanguage,
} from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAApiErrorCode, TargetIroInputPy, useIaAPI } from '@hooks/useIaApi';
import { XIcon } from '../../../../icons';
import { LoaderFullscreen } from '../../../../layout/Loader';
import { IroSuggestionTable } from './IroSuggestionTable';
import { v4 as uuidv4 } from 'uuid';
import { useRightDrawer } from '../../../../layout/RightDrawer';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';

export type Suggestion = {
  description: string;
  randomId?: string;
  added?: boolean;
};

export const IroSuggestionsDrawer = ({
  iroType,
  stake,
  referentialId,
  iros,
  topics,
}: {
  iroType: IroType;
  stake: IroEvaluationHeader_StakeFragment;
  referentialId: string;
  iros: (
    | FinancialRiskRow_RiskIroFragment
    | FinancialOpportunityRow_OpportunityIroFragment
    | PositiveImpactRow_PositiveImpactIroFragment
    | NegativeImpactRow_NegativeImpactIroFragment
  )[];
  topics: string[];
}) => {
  const drawer = useRightDrawer();
  const { t, translateProperty } = useTranslation();
  //TODO : translate pillar and stakeName and iros with appropriate language when IA multi-language is ready
  const pillar = translateProperty(
    stake.pillar,
    'name',
    SurveyLanguage.Fr,
    true,
  );
  const stakeName = translateProperty(stake, 'name', SurveyLanguage.Fr, true);
  const [targetInput, setTargetInput] = useState<TargetIroInputPy>({
    inputs: {
      keywords: [],
      stake: stakeName,
      topics: topics,
      pillar,
      existingIros: iros.map((iro) =>
        translateProperty(iro, 'name', SurveyLanguage.Fr, true),
      ),
      type: iroType,
    },
  });
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [apiError, setApiError] = useState<IAApiErrorCode | null>();

  const mapSuggestions = (response: Suggestion[] | IAApiErrorCode) => {
    if (response instanceof Array) {
      setSuggestions(
        response.map((suggestion: Suggestion) => {
          return {
            description: suggestion.description,
            randomId: uuidv4(),
          };
        }),
      );
      setApiError(null);
    } else {
      setApiError(response);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { isValid },
    resetField,
  } = useForm();

  const onSubmit = async (data: any) => {
    const keywords = [...targetInput.inputs.keywords] || [];
    keywords.push(data.description);
    const newTargetInput = { ...targetInput };
    newTargetInput.inputs.keywords = keywords;
    newTargetInput.inputs.existingIros = iros.map((iro) =>
      translateProperty(iro, 'name', SurveyLanguage.Fr, true),
    );
    newTargetInput.inputs.type = iroType;
    setTargetInput(newTargetInput);
    resetField('description');
  };

  const { loading } = useIaAPI({
    path: '/',
    query: targetInput,
    setState: mapSuggestions,
  });

  function explodeKeywords(
    targetInput: TargetIroInputPy,
  ): { field: 'pillar' | 'stake' | 'description' | 'topics'; value: string }[] {
    const result: {
      field: 'pillar' | 'stake' | 'description' | 'topics';
      value: string;
    }[] = targetInput.inputs.pillar
      ? [{ field: 'pillar', value: targetInput.inputs.pillar }]
      : [];
    if (targetInput.inputs.stake) {
      result.push({ field: 'stake', value: targetInput.inputs.stake });
    }
    if (targetInput.inputs.topics) {
      for (const topic of targetInput.inputs.topics) {
        result.push({ field: 'topics', value: topic });
      }
    }
    if (targetInput.inputs.keywords) {
      targetInput.inputs.keywords.map((keyword) => {
        result.push({ field: 'description', value: keyword });
      });
    }
    return result;
  }

  function removeKeyword(item: {
    field: 'pillar' | 'stake' | 'description' | 'topics';
    value: string;
  }) {
    if (item.field === 'pillar' || item.field === 'stake') {
      targetInput.inputs[item.field] = targetInput.inputs[item.field]?.replace(
        item.value,
        '',
      );
    } else if (item.field === 'description') {
      targetInput.inputs.keywords = targetInput.inputs.keywords.filter(
        (keyword) => keyword !== item.value,
      );
    } else if (item.field === 'topics') {
      targetInput.inputs.topics = targetInput.inputs.topics?.filter(
        (topic) => topic !== item.value,
      );
    }
    setTargetInput({ ...targetInput });
  }

  return (
    <>
      <div className={'flex flex-col h-full'}>
        <div className="px-8 py-4 bg-gray-50 border-b border-gray-100 flex gap-4 items-center">
          <button className="unstyled " onClick={drawer.closeDrawer}>
            <XIcon className="h-5 w-5" />
          </button>
          <h3>{t('translation:iro.suggestions.title.' + iroType)}</h3>
        </div>
        <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
          <div className="space-y-6">
            <div className="flex flex-col gap-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-1 ">
                  <label htmlFor="keywords" className={'mb-2 font-extrabold'}>
                    {t('translation:iro.suggestions.keywords')}
                  </label>
                  <div className="border-2 border-gray-100 p-1 rounded-lg flex items-center justify-between hover:shadow">
                    <input
                      type="text"
                      onKeyDownCapture={(event) => {
                        if (event.key === 'Enter') handleSubmit(onSubmit);
                      }}
                      {...register('description', {
                        required: true,
                        minLength: 3,
                      })}
                      placeholder={t(
                        'translation:iro.suggestions.keywordsPlaceHolder',
                      )}
                      style={{ boxShadow: 'none' }}
                      className="border-none bg-none shadow-none"
                    ></input>
                    <button
                      hidden={!isValid}
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isValid}
                      className="bg-black text-white hover:bg-gray-800 hover:border-gray-800 hover:text-white"
                    >
                      {t('global:search')}
                    </button>
                  </div>
                </div>
              </form>
              <div className="flex gap-1 flex-wrap mb-2">
                {targetInput &&
                  explodeKeywords(targetInput).map((item) => (
                    <div
                      key={item.value + uuidv4()}
                      className="text-blue-900 text-xs font-semibold bg-blue-100 rounded-lg p-2 flex gap-1"
                    >
                      {item.value}
                      <button
                        className="unstyled"
                        onClick={() => removeKeyword(item)}
                      >
                        <XIcon className="w-4 h-4 text-blue-800" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            {loading && <LoaderFullscreen />}
            {!loading &&
              apiError &&
              IAApiErrorCode.API_NOT_READY === apiError && (
                <MessageBox type={MessageBoxType.Warning}>
                  {t('translation:iro.suggestions.apiNotReadyMessage')}
                </MessageBox>
              )}
            {!loading &&
              apiError &&
              apiError === IAApiErrorCode.WRONG_QUERY && (
                <MessageBox type={MessageBoxType.Error}>
                  {t('translation:iro.suggestions.queryEmpty')}
                </MessageBox>
              )}
            {!loading &&
              !apiError &&
              (targetInput?.inputs.type.includes(iroType) ||
                targetInput?.inputs.type.length == 0) && (
                <IroSuggestionTable
                  type={iroType}
                  stake={stake}
                  referentialId={referentialId}
                  iros={suggestions}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};
