export function getTimeFromISOString(
  isoString: string,
  i18nLanguage: string,
): string {
  const date = new Date(isoString);
  return date.toLocaleTimeString(mapLanguageToLocale(i18nLanguage), {
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function getDateFromISOString(
  isoString: string,
  i18nLanguage: string,
): string {
  const date = new Date(isoString);
  return date.toLocaleDateString(mapLanguageToLocale(i18nLanguage), {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}

function mapLanguageToLocale(i18nLanguage: string): string {
  switch (i18nLanguage) {
    case 'fr':
      return 'fr-FR';
    case 'en':
      return 'en-US';
    default:
      return 'fr-FR';
  }
}
