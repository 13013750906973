import { IndicatorItem_IndicatorFragment } from '../../../../../../../graphql/generated';
import React from 'react';
import { OrganizationalUnitDropdown } from '../../../../../project/company/OrganizationalUnitDropdown';
import { CompanyLogo } from '../../../../../project/CompanyLogo';
import { ChevronDownIcon } from '../../../../../../icons';
import { IndicatorValueByDimensionForm } from './IndicatorValueByDimensionForm';
import { IndicatorValueForm } from './IndicatorValueForm';
import { IndicatorValueFormProvider } from '../IndicatorValueFormContext';
import { IndicatorValueFormContainer } from '../IndicatorValueFormContainer';

export const IndicatorValueFormsForEachOrgUnit = ({
  indicator,
}: {
  indicator: IndicatorItem_IndicatorFragment;
}) => {
  const flattenedSubsidiariesItems =
    indicator.enterprise.subsidiaries?.flatMap((subsidiary) => [
      subsidiary,
      ...(subsidiary.subsidiaries?.flatMap((subSubsidiary) => [
        subSubsidiary,
        ...(subSubsidiary.subsidiaries || []),
      ]) || []),
    ]) || [];

  const [orgIdExpanded, setOrgIdExpanded] = React.useState<string | null>(null);

  const expandOrgUnit = (orgId: string) => {
    if (orgIdExpanded === orgId) {
      setOrgIdExpanded(null);
    } else {
      setOrgIdExpanded(orgId);
    }
  };

  const [selectedOrgUnitId, setSelectedOrgUnitId] = React.useState<
    string | null
  >(null);

  const selectOrgUnit = (id: string | null) => {
    setSelectedOrgUnitId(id);
    setOrgIdExpanded(id);
  };

  return (
    <div className="space-y-4">
      <div className={'w-1/2'}>
        <OrganizationalUnitDropdown
          selectedOrgUnitId={selectedOrgUnitId}
          setSingleSelectedOrgUnitId={selectOrgUnit}
        />
      </div>
      <div className="space-y-4 divide-y divide-gray-100">
        {flattenedSubsidiariesItems
          .filter((orgUnit) =>
            selectedOrgUnitId ? orgUnit.id === selectedOrgUnitId : true,
          )
          .map((orgUnit) => (
            <div className="space-y-2 pt-4" key={'org-unit' + orgUnit.id}>
              <div
                className="flex items-center justify-between gap-2 cursor-pointer"
                onClick={() => expandOrgUnit(orgUnit.id)}
              >
                <div className="flex items-center gap-2">
                  <CompanyLogo companyId={orgUnit.id} size={'small'} />
                  <h5>{orgUnit.name}</h5>
                </div>
                <button
                  className="unstyled bg-transparent"
                  onClick={() => expandOrgUnit(orgUnit.id)}
                >
                  <ChevronDownIcon
                    className={orgIdExpanded === orgUnit.id ? 'rotate-180' : ''}
                  />
                </button>
              </div>
              {orgIdExpanded === orgUnit.id && (
                <IndicatorValueFormProvider
                  indicator={indicator}
                  key={orgUnit.id}
                  orgUnit={orgUnit.id}
                >
                  <IndicatorValueFormContainer>
                    {(indicator.dimensions?.length > 0 && (
                      <IndicatorValueByDimensionForm
                        indicator={indicator}
                        enterpriseId={orgUnit.id}
                      />
                    )) || (
                      <IndicatorValueForm
                        indicator={indicator}
                        enterpriseId={orgUnit.id}
                      />
                    )}
                  </IndicatorValueFormContainer>
                </IndicatorValueFormProvider>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
