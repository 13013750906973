import React, { useState } from 'react';
import { Filters } from './iroFilters.type';
import { useRightDrawer } from '../../../layout/RightDrawer';
import { XIcon } from '../../../icons';
import PillarDropdown from '../../../form/PillarDropdown';
import { OrganizationalUnitDropdown } from '../../project/company/OrganizationalUnitDropdown';
import StakeDropdown from '../../../form/StakeDropdown';
import { IroType, ValueChainItemEnum } from '../../../../graphql/generated';
import { Checkbox } from '../../../form/Checkbox';
import { iroTypeToTypename } from '../iroTypenameToIroType';
import { useTranslation } from '@hooks/useTranslation';
import { IroIcon } from '../IroIcon';

function IroTypeCheckBox(props: {
  typeName: string;
  checked: boolean;
  onChange: () => void;
  label?: string;
}) {
  return (
    <div className="flex items-center gap-4 font-semibold my-2">
      <Checkbox checked={props.checked} onChange={props.onChange}></Checkbox>

      <div className={'w-4 flex justify-center'}>
        <IroIcon iroTypename={props.typeName} />
      </div>
      <span>{props.label}</span>
    </div>
  );
}

export function FilterIroDrawer({
  filters,
  setFilters,
}: {
  filters: Filters;
  setFilters: (filters: Filters) => void;
}) {
  const drawer = useRightDrawer();
  const [localFilters, setLocalFilters] = useState(filters);
  const { t } = useTranslation();
  const setPillarId = (pillarId: string | null | undefined) => {
    const newFilters = { ...localFilters, pillarId };
    setLocalFilters(newFilters);
    setFilters(newFilters);
  };

  const setOrganizationIds = (
    organizationUnitIds: string | string[] | null | undefined,
  ) => {
    let newFilters;
    if (!organizationUnitIds) {
      newFilters = { ...localFilters, organizationUnitIds: null };
    } else if (organizationUnitIds instanceof Array) {
      newFilters = { ...localFilters, organizationUnitIds };
    } else {
      newFilters = {
        ...localFilters,
        organizationUnitIds: [organizationUnitIds],
      };
    }
    setLocalFilters(newFilters);
    setFilters(newFilters);
  };

  const setStake = (stakeId: string | null | undefined) => {
    const newFilters = { ...localFilters, stakeId };
    setLocalFilters(newFilters);
    setFilters(newFilters);
  };

  function toggleIroTypeSelection(iroType: IroType) {
    const iroTypes = localFilters.iroTypes || [];
    let newFilters;
    const iroTypeName = iroTypeToTypename(iroType);
    if (iroTypes.includes(iroTypeName)) {
      const newIroTypes = iroTypes.filter((type) => type !== iroTypeName);
      newFilters = { ...localFilters, iroTypes: newIroTypes };
    } else {
      newFilters = { ...localFilters, iroTypes: [...iroTypes, iroTypeName] };
    }
    setLocalFilters(newFilters);
    setFilters(newFilters);
  }

  function isIroTypeSelected(iroType: IroType) {
    const iroTypes = localFilters.iroTypes || [];
    const iroTypeName = iroTypeToTypename(iroType);
    return iroTypes.includes(iroTypeName);
  }

  const toggleValueChainPosition = (
    valueChainPositionInput: ValueChainItemEnum,
  ) => {
    const valueChainItemSelected = localFilters.valueChainPositions || [];
    let newFilters;
    if (
      valueChainPositionInput &&
      valueChainItemSelected.includes(valueChainPositionInput)
    ) {
      const newIroTypes = valueChainItemSelected.filter(
        (valueChainItem) => valueChainItem !== valueChainPositionInput,
      );
      newFilters = { ...localFilters, valueChainPositions: newIroTypes };
      if (valueChainPositionInput === ValueChainItemEnum.OwnOperations) {
        newFilters = { ...newFilters, organizationUnitIds: null };
      }
    } else {
      newFilters = {
        ...localFilters,
        valueChainPositions: [
          ...valueChainItemSelected,
          valueChainPositionInput,
        ],
      };
    }
    setLocalFilters(newFilters);
    setFilters(newFilters);
  };

  function hasFilters() {
    return (
      localFilters.pillarId ||
      localFilters.stakeId ||
      localFilters.iroTypes?.length ||
      localFilters.valueChainPositions?.length ||
      localFilters.organizationUnitIds?.length
    );
  }

  function resetFilters() {
    setLocalFilters({});
    setFilters({});
  }

  function isSelectedValueChainPosition(position: ValueChainItemEnum) {
    return !!localFilters.valueChainPositions?.includes(position);
  }

  return (
    <>
      <div className={'flex flex-col h-full'}>
        <div className="px-8 py-4 bg-gray-50 border-b border-gray-100 flex gap-4 items-center">
          <button className="unstyled " onClick={drawer.closeDrawer}>
            <XIcon className="h-5 w-5" />
          </button>
          <h3>{t('global:filters')}</h3>
        </div>
        <div className="my-8 mx-4 space-y-2 px-4">
          <div className="py-2 space-y-2">
            <h6>{t('iro.list.filters.pillars')}</h6>
            <PillarDropdown
              currentPillarId={localFilters.pillarId}
              setPillarId={setPillarId}
            ></PillarDropdown>
          </div>
          <div className="py-2 space-y-2">
            <h6>{t('iro.list.filters.stakes')}</h6>
            <StakeDropdown
              isRequired={false}
              currentStakeId={localFilters.stakeId}
              setCurrentStakeId={setStake}
            />
          </div>

          <div className="py-2 space-y-2">
            <h6>{t('iro.list.filters.iroTypes')}</h6>
            <div className="ml-4 space-y-2">
              <IroTypeCheckBox
                typeName={iroTypeToTypename(IroType.PositiveImpact)}
                checked={isIroTypeSelected(IroType.PositiveImpact)}
                onChange={() => toggleIroTypeSelection(IroType.PositiveImpact)}
                label={t(`enum:iroType.${IroType.PositiveImpact}`)}
              />
              <IroTypeCheckBox
                typeName={iroTypeToTypename(IroType.NegativeImpact)}
                checked={isIroTypeSelected(IroType.NegativeImpact)}
                onChange={() => toggleIroTypeSelection(IroType.NegativeImpact)}
                label={t(`enum:iroType.${IroType.NegativeImpact}`)}
              />

              <IroTypeCheckBox
                typeName={iroTypeToTypename(IroType.Risk)}
                checked={isIroTypeSelected(IroType.Risk)}
                onChange={() => toggleIroTypeSelection(IroType.Risk)}
                label={t(`enum:iroType.${IroType.Risk}`)}
              />

              <IroTypeCheckBox
                typeName={iroTypeToTypename(IroType.Opportunity)}
                checked={isIroTypeSelected(IroType.Opportunity)}
                onChange={() => toggleIroTypeSelection(IroType.Opportunity)}
                label={t(`enum:iroType.${IroType.Opportunity}`)}
              />
            </div>
          </div>

          <div className="py-2 space-y-2">
            <h6>{t('iro.list.filters.valueChain')}</h6>
            <div className="ml-4 space-y-2">
              <div className="flex items-center gap-4 font-semibold my-2">
                <Checkbox
                  checked={isSelectedValueChainPosition(
                    ValueChainItemEnum.Upstream,
                  )}
                  onChange={() =>
                    toggleValueChainPosition(ValueChainItemEnum.Upstream)
                  }
                ></Checkbox>

                <div>
                  {t(`enum:valueChainItem.${ValueChainItemEnum.Upstream}`)}
                </div>
              </div>
              <div className="flex items-center gap-4 font-semibold my-2">
                <Checkbox
                  checked={isSelectedValueChainPosition(
                    ValueChainItemEnum.OwnOperations,
                  )}
                  onChange={() =>
                    toggleValueChainPosition(ValueChainItemEnum.OwnOperations)
                  }
                ></Checkbox>

                <div>
                  {t(`enum:valueChainItem.${ValueChainItemEnum.OwnOperations}`)}
                </div>
              </div>
              {isSelectedValueChainPosition(
                ValueChainItemEnum.OwnOperations,
              ) && (
                <OrganizationalUnitDropdown
                  selectedOrgUnitId={localFilters.organizationUnitIds || null}
                  setMultipleSelectedOrgUnitId={setOrganizationIds}
                  multiple={true}
                ></OrganizationalUnitDropdown>
              )}
              <div className="flex items-center gap-4 font-semibold my-2">
                <Checkbox
                  checked={isSelectedValueChainPosition(
                    ValueChainItemEnum.Downstream,
                  )}
                  onChange={() =>
                    toggleValueChainPosition(ValueChainItemEnum.Downstream)
                  }
                ></Checkbox>

                <div>
                  {t(`enum:valueChainItem.${ValueChainItemEnum.Downstream}`)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow shadow-inner rotate-180"></div>
        {hasFilters() && (
          <div className="px-8 py-4 text-gray-500 border-t border-gray-100 flex gap-4 items-center">
            <a
              className="underline cursor-pointer font-semibold"
              onClick={() => resetFilters()}
            >
              {t('global:clear')}
            </a>
          </div>
        )}
      </div>
    </>
  );
}
