import React from 'react';
import { DisclosureRequirement_ArFragment } from '../../../../../graphql/cms/generated';
import { filterEmptyDocuments } from '../../../../cms/utils';
import { BlockWithDatapointsRenderer } from '../BlockWithDatapointsRenderer';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Footnotes } from './Footnotes';
import { ApplicationRequirementSubChapterContent } from './ApplicationRequirementSubChapterContent';

export const ApplicationRequirementContent = ({
  applicationRequirement,
}: {
  applicationRequirement: DisclosureRequirement_ArFragment;
}) => {
  const footnotes =
    applicationRequirement.footnotes?.filter(filterEmptyDocuments) || [];
  const datapoints =
    applicationRequirement.datapoints.filter(filterEmptyDocuments);
  const subChapters =
    applicationRequirement.sub_chapter?.filter(filterEmptyDocuments) || [];
  return (
    <div>
      <div className="space-y-2 w-full">
        {datapoints.length > 0 ? (
          <BlockWithDatapointsRenderer
            content={applicationRequirement.content}
            datapoints={datapoints.map((d) => d.slug || '')}
          />
        ) : (
          <BlocksRenderer content={applicationRequirement.content} />
        )}
        {footnotes.length > 0 && <Footnotes footnotes={footnotes} />}
      </div>
      <div className="space-y-2 pl-4">
        {subChapters.map((subChapter, index) => (
          <ApplicationRequirementSubChapterContent
            subChapter={subChapter}
            key={`sub_chapter_${index}`}
          />
        ))}
      </div>
    </div>
  );
};
