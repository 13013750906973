import React, { useEffect, useState } from 'react';
import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown.types';
import StringDropdown from '../../../generic/dropdown/StringDropdown';
import { CompanyLogo } from '../CompanyLogo';
import { useTranslation } from '@hooks/useTranslation';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import {
  SubsidiariesFragment,
  useOrganizationalUnitDropdownQuery,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';

function buildStringDropdownItem(enterprise: {
  id: string;
  name: string;
}): StringDropdownItem {
  return {
    id: enterprise.id,
    label: enterprise.name,
    icon: (
      <CompanyLogo
        companyId={enterprise.id}
        size={'small'}
        fallbackBgColor="bg-yellow-100"
      />
    ),
  };
}

function findSubsidiaryInCompany(
  company: {
    name: string;
    id: string;
    subsidiaries?: SubsidiariesFragment[] | null | undefined;
  },
  id: any,
): StringDropdownItem | undefined {
  if (company.id === id) {
    return buildStringDropdownItem(company);
  } else {
    if (company.subsidiaries) {
      for (let i = 0; i < company.subsidiaries.length; i++) {
        const item = findSubsidiaryInCompany(company.subsidiaries[i], id);
        if (item) {
          return item;
        }
      }
    }
  }
  return undefined;
}

export function OrganizationalUnitDropdown({
  selectedOrgUnitId,
  setSingleSelectedOrgUnitId,
  setMultipleSelectedOrgUnitId,
  multiple,
}: {
  selectedOrgUnitId: string | string[] | null;
  setSingleSelectedOrgUnitId?: (id: string | null) => void;
  setMultipleSelectedOrgUnitId?: (id: string[] | null) => void;
  multiple?: boolean;
}) {
  const currentCompany = useProjectContext();
  const companyQuery = useOrganizationalUnitDropdownQuery({
    variables: {
      companyId: currentCompany?.enterprise?.id || '',
    },
    fetchPolicy: 'network-only',
  });
  const company = companyQuery.data?.enterprise;

  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState<
    StringDropdownItem[] | StringDropdownItem | null
  >(multiple ? [] : null);

  let items: (StringDropdownItem | undefined)[] = [];
  let item: StringDropdownItem | undefined;

  function pickItemFromIds() {
    if (company && selectedOrgUnitId) {
      if (selectedOrgUnitId instanceof Array) {
        items = selectedOrgUnitId.map((id) =>
          findSubsidiaryInCompany(company, id),
        );
        pickItem(items as StringDropdownItem[]);
      } else {
        item = findSubsidiaryInCompany(company, selectedOrgUnitId);
        pickItem(item as StringDropdownItem);
      }
    } else {
      if (multiple) {
        pickItem([]);
      } else {
        pickItem(null);
      }
    }
  }

  const pickItem = (item: StringDropdownItem | StringDropdownItem[] | null) => {
    if (item instanceof Array) {
      const selectedIds: string[] = item
        .filter((sdi) => !!sdi.id)
        .map((item) => item.id!);
      if (
        isEqualIdArray(
          selectedIds,
          (currentItem as StringDropdownItem[]).map((element) => element.id),
        )
      )
        return;
      if (setMultipleSelectedOrgUnitId) {
        setMultipleSelectedOrgUnitId(selectedIds);
      } else {
        console.error('setSingleSelectedOrgUnitId is not defined');
      }
      setCurrentItem(item);
    } else {
      if (currentItem && item?.id === (currentItem as StringDropdownItem).id)
        return;
      if (setSingleSelectedOrgUnitId) {
        setSingleSelectedOrgUnitId(item?.id || null);
      } else {
        console.error('setSingleSelectedOrgUnitId is not defined');
      }
      if (item !== null) {
        setCurrentItem(item);
      } else {
        setCurrentItem(null);
      }
    }
  };
  useEffect(() => {
    pickItemFromIds();
  }, [company, selectedOrgUnitId]);

  const flattenedSubsidiariesItems: StringDropdownItem[] =
    company?.subsidiaries?.flatMap((subsidiary) => [
      {
        id: subsidiary.id,
        label: subsidiary.name,
        icon: (
          <CompanyLogo
            companyId={subsidiary.id}
            size={'small'}
            fallbackBgColor="bg-yellow-100"
          />
        ),
      },
      ...(subsidiary.subsidiaries?.flatMap((subSubsidiary) => [
        {
          id: subSubsidiary.id,
          label: `-- ${subSubsidiary.name}`,
          icon: (
            <div className="ml-4">
              <CompanyLogo
                companyId={subsidiary.id}
                size={'small'}
                fallbackBgColor="bg-yellow-100"
              />
            </div>
          ),
        },

        ...(subSubsidiary.subsidiaries?.flatMap((subSubSubsidiary) => ({
          id: subSubSubsidiary.id,
          label: `-- ${subSubSubsidiary.name}`,
          icon: (
            <div className="ml-8">
              <CompanyLogo
                companyId={subSubSubsidiary.id}
                size={'small'}
                fallbackBgColor="bg-yellow-100"
              />
            </div>
          ),
        })) || []),
      ]) || []),
    ]) || [];

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div>
      {flattenedSubsidiariesItems.length > 0 ? (
        <StringDropdown
          multiple={multiple}
          availableItems={flattenedSubsidiariesItems}
          item={currentItem || null}
          setItem={pickItem}
          filterable={true}
          allowEmpty={true}
        />
      ) : (
        <input
          type="text"
          className="form-input-text disabled"
          disabled={true}
          value={t('global:noResult')}
        />
      )}
    </div>
  );
}

function isEqualIdArray(a: string[], b: (string | null)[]): boolean {
  return a.length === b.length && a.every((value, index) => value === b[index]);
}
