import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { tailwindColors } from '../../../services/TailwindService';

export function ProgressDoughnutChart({
  progress,
  total,
  progressLabel,
  totalLabel,
  title,
}: {
  progress: number;
  total: number;
  progressLabel: string;
  totalLabel: string;
  title: string;
}) {
  const doughnutChartData = {
    labels: [progressLabel, totalLabel],
    datasets: [
      {
        data: [progress, total - progress],
        backgroundColor: [
          tailwindColors.green['300'],
          tailwindColors.gray['100'],
        ],
      },
    ],
  };

  const doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-4">
      <div className="flex items-center gap-4">
        <div className="w-1/3">
          <Doughnut
            data={doughnutChartData}
            options={doughnutChartOptions}
            title={title}
          />
        </div>
        <div className="space-y-2 text-sm">
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-green-300 rounded-full"></div>
            <div>
              {progressLabel} ({progress})
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-gray-100 rounded-full"></div>
            <div>
              {totalLabel} ({total})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
