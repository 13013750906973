import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useFirebaseApp } from '../providers/FirebaseProvider';
import { IroType } from '../graphql/generated';
import { Suggestion } from '../components/project/iro/evaluation/suggestionsDrawer/IroSuggestionsDrawer';

export type TargetIroInputPy = {
  inputs: {
    keywords: string[];
    stake: string;
    topics: string[];
    pillar: string;
    existingIros: string[];
    type: IroType;
    size?: number;
  };
};

export enum IAApiErrorCode {
  API_NOT_READY = 'API_NOT_READY',
  WRONG_QUERY = 'WRONG_QUERY',
}

interface UseIaApiProps {
  path: string;
  query: TargetIroInputPy | undefined;
  setState: (response: Suggestion[] | IAApiErrorCode) => void;
}

function fetchApi({
  setLoading,
  apiUrl,
  path,
  setState,
  query,
}: {
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
  apiUrl: string;
  path: string;
  setState: (response: any) => void;
  query?: TargetIroInputPy | undefined;
}) {
  //const { t } = useTranslation();
  setLoading(true);
  fetch(`${apiUrl}${path}`, {
    method: 'POST',
    headers: {
      //'x-wait-for-model': 'true', //commented because it s make user wait too long on warmup
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',

      // Authorization: authorizationHeader
    },
    body: query ? JSON.stringify(query) : JSON.stringify({}),
  })
    .then((result: Response) => {
      if (!result.ok) {
        //api is not well documented regarding reboot (at least I could not find it), then I discovered several status when api is not ready, or rebooting, or paused
        if (
          result.status === 400 ||
          result.status === 503 ||
          result.status === 500
        ) {
          return IAApiErrorCode.API_NOT_READY;
        }
      }
      return result.json();
    })
    .then((json: any) => {
      setState(json);
    })
    .catch((error) => {
      //toast.openToastWithError(error.message);
    })
    .finally(() => {
      setLoading(false);
    });
}

export const useIaAPI = ({ path = '/', query, setState }: UseIaApiProps) => {
  const firebaseApp = useFirebaseApp();
  const currentUser = getAuth(firebaseApp).currentUser;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (currentUser == null || loading) return;

    if (
      query?.inputs.keywords.length === 0 &&
      (!query?.inputs.pillar || query?.inputs.pillar === '') &&
      (!query?.inputs.stake || query?.inputs.stake === '') &&
      query?.inputs.topics.length === 0
    ) {
      setState(IAApiErrorCode.WRONG_QUERY);
      return;
    }
    const apiUrl = process.env.REACT_APP_API_IROGEN_URL;
    if (apiUrl) {
      fetchApi({
        setLoading,
        apiUrl,
        path,
        query,
        setState,
      });
    }
  }, [query]);

  return { loading };
};
